import styled from "styled-components";
import { devices } from "../Components/Devices";

export const WholeFooterContainer = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 600px;
    padding: 1px;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 600px;
    padding: 1px;
  }

  @media ${devices.laptopS} {
    max-width: 1366px;
    height: 1500px;
    padding: 1px;
  }

  @media ${devices.laptopL} {
    max-width: 100%;
    height: 900px;
    padding: 1px;
  }
`;

export const MobileOrangeContainer = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 60%;
    position: relative;
    z-index: 2;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 50%;
    position: relative;
    z-index: 2;
  }
`;

export const FooterModelImage = styled.img`
  @media ${devices.mobile} {
    height: 120%;
    position: absolute;
    bottom: -17px;
    right: 0;
    z-index: 1;
  }

  @media ${devices.tablet} {
    height: 100%;
    position: absolute;
    bottom: -27px;
    right: 25px;
    z-index: 1;
  }

  @media ${devices.laptopS} {
    height: 100%;
    position: absolute;
    bottom: -40px;
    right: 45px;
    z-index: 1;
  }

  @media ${devices.laptopL} {
    height: 100%;
    position: absolute;
    bottom: -40px;
    right: 35px;
    z-index: 1;
  }
`;

export const MobileOrangeImage = styled.img`
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const FooterHalfCon = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
  }
`;

export const HalfContainer = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopL} {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const SocMedContainer = styled.div`
  @media ${devices.mobile} {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }

  @media ${devices.tablet} {
    width: 70%;
    height: 80%;
    z-index: 2;
  }

  @media ${devices.laptopS} {
    width: 70%;
    height: 80%;
    z-index: 2;
  }

  @media ${devices.laptopL} {
    width: 100%;
    height: 80%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const FooterLink = styled.p`
  @media ${devices.mobile} {
    font-size: 8px;
    font-weight: 700;
    color: white;
    cursor: pointer;
    text-decoration: none;
  }

  @media ${devices.tablet} {
    font-size: 16px;
    font-weight: 700;
    color: white;
    cursor: pointer;
    text-decoration: none;
  }

  @media ${devices.laptopS} {
    font-size: 20px;
    font-weight: 700;
    color: white;
    margin: 5px 0;
    cursor: pointer;
    text-decoration: none;
  }

  @media ${devices.laptopL} {
    font-size: 20px;
    font-weight: 700;
    color: white;
    margin: 5px 0;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const SocMedHalfContainer = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  @media ${devices.laptopL} {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
`;

export const FooterContactUs = styled.button`
  @media ${devices.mobile} {
    padding: 5px 10px;
    background-color: #003f88;
    color: white;
    border-radius: 20px;
    border: none;
    box-shadow: 1px 1px 8px #003f88;

    &:hover {
      background-color: lightblue;
    }
  }

  @media ${devices.tablet} {
    padding: 10px 20px;
    background-color: #003f88;
    color: white;
    border-radius: 20px;
    border: none;
    box-shadow: 1px 1px 8px #003f88;

    &:hover {
      background-color: lightblue;
    }
  }

  @media ${devices.laptopS} {
    padding: 10px 40px;
    background-color: #003f88;
    color: white;
    border-radius: 20px;
    border: none;
    box-shadow: 1px 1px 8px #003f88;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: lightblue;
    }

    &:active {
      background-color: #003f88;
    }
  }

  @media ${devices.laptopL} {
    padding: 20px 80px;
    margin: 0 20px;
    background-color: #003f88;
    font-size: 20px;
    color: white;
    border-radius: 20px;
    border: none;
    box-shadow: 1px 1px 8px #003f88;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: lightblue;
      color: black;
    }

    &:active {
      background-color: #003f88;
    }
  }
`;

export const SocMed = styled.img`
  @media ${devices.mobile} {
    height: 20px;
    margin: 0 8px;
  }

  @media ${devices.tablet} {
    height: 40px;
    margin: 0 10px;
  }

  @media ${devices.laptopS} {
    cursor: pointer;
    transition: 0.3s;
    margin: 0 20px;

    &:hover {
      filter: invert();
    }
  }
`;

export const CopyrightContainer = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 55px;
    z-index: 2;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 100px;
    z-index: 2;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Boundless = styled.p`
  @media ${devices.mobile} {
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    color: #00509d;
  }

  @media ${devices.tablet} {
    font-size: 25px;
    font-style: italic;
    font-weight: 700;
    color: #00509d;
  }
`;

export const SilverHr = styled.hr`
  width: 80%;
  border: 1px solid #ffffff;
  margin: 10px 0;
`;

export const Copyright = styled.p`
  @media ${devices.mobile} {
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
  }

  @media ${devices.tablet} {
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
  }
`;
