import styled from "styled-components";
import { devices } from "../Components/Devices";

export const WholeHomepageBody = styled.div`
  width: 100%;
  height: calc(100vh - 65px);
  position: absolute;
  top: 65px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1a66cc26;
    border-radius: 5px;

    &:hover {
      background-color: #00296b;
    }
  }
`;

export const HomepageBody = styled.div`
  max-width: 1366px;
`;

// BANNER MOBILE STYLES
export const MobileBannerCOntainer = styled.div`
  @media ${devices.mobile} {
    display: flex;
    max-width: 450px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 700px;
  }

  @media ${devices.tablet} {
    display: flex;
    max-width: 800px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 800px;
  }

  @media ${devices.laptopS} {
    display: none;
  }

  @media ${devices.laptopL} {
    display: none;
  }
`;

export const MobileBannerCon = styled.div`
  @media ${devices.mobile} {
    width: 99%;
    height: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -80px;
  }

  @media ${devices.tablet} {
    width: 99%;
    height: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -55px;
  }

  @media ${devices.laptopS} {
    display: none;
  }

  @media ${devices.laptopL} {
    display: none;
  }
`;

export const CircleOneMobile = styled.div`
  @media ${devices.mobile} {
    width: 300px;
    height: 300px;
    border: 3px solid #00509d33;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 400px;
    height: 400px;
    border: 3px solid #00509d33;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    display: none;
  }

  @media ${devices.laptopL} {
    display: none;
  }
`;

export const CircleTwoMobile = styled.div`
  @media ${devices.mobile} {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    border: 3px solid #00509d;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    border: 3px solid #00509d;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    display: none;
  }

  @media ${devices.laptopL} {
    display: none;
  }
`;

export const CircleThreeMobile = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid #fff;
    border-left: none;
    border-top: none;
    border-right: none;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid #fff;
    border-left: none;
    border-top: none;
    border-right: none;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    display: none;
  }

  @media ${devices.laptopL} {
    display: none;
  }
`;

export const MobilePhotoCon = styled.div`
  @media ${devices.mobile} {
    height: 300px;
    width: 300px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  @media ${devices.tablet} {
    max-height: 300px;
    max-width: 300px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  @media ${devices.laptopS} {
    display: none;
  }

  @media ${devices.laptopL} {
    display: none;
  }
`;

export const MobileBannerPhoto = styled.img`
  max-height: 90%;
`;

export const OrangeBackground = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 50%;
    background-color: #fdc50033;
    z-index: 2;
    border-radius: 2px 2px 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 50%;
    background-color: #fdc50033;
    z-index: 2;
    border-radius: 2px 2px 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    display: none;
  }

  @media ${devices.laptopL} {
    display: none;
  }
`;

export const OrangeCenterContainer = styled.div`
  @media ${devices.mobile} {
    width: 90%;
    height: 70%;
    border-radius: 296.5px, 0px, 80.5px, 296.5px;
    background-color: #fdc500;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 100px 120px 120px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }

  @media ${devices.tablet} {
    width: 90%;
    height: 70%;
    border-radius: 296.5px, 0px, 80.5px, 296.5px;
    background-color: #fdc500;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 100px 120px 120px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }

  @media ${devices.laptopS} {
    display: none;
  }

  @media ${devices.laptopL} {
    display: none;
  }
`;

export const MobileGreetings = styled.p`
  @media ${devices.mobile} {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    color: #202020;
    margin-top: 40px;
    padding: 5px;
  }

  @media ${devices.tablet} {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    color: #202020;
    margin-top: 30px;
  }

  @media ${devices.laptopS} {
    display: none;
  }

  @media ${devices.laptopL} {
    display: none;
  }
`;

export const MobileOwnerName = styled.p`
  @media ${devices.mobile} {
    font-size: 14px;
    font-weight: 700;
    color: #202020;
    padding: 15px 0 0 0;
  }

  @media ${devices.tablet} {
    font-size: 16px;
    font-weight: 700;
    color: #202020;
    padding: 50px 0 0 0;
  }

  @media ${devices.laptopS} {
    display: none;
  }

  @media ${devices.laptopL} {
    display: none;
  }
`;

export const MobileOwnerPosition = styled.p`
  font-size: 12px;
  font-weight: 300;
  color: #202020;
`;

export const MobileBottomContainer = styled.div`
  @media ${devices.mobile} {
    height: 25%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @media ${devices.tablet} {
    height: 25%;
    width: 100%;
    display: flex;
  }

  @media ${devices.laptopS} {
    display: none;
  }

  @media ${devices.laptopL} {
    display: none;
  }
`;

export const MobBottomHalfCon = styled.div`
  width: 100%;
  height: ${({ props }) => (props === "smaller" ? "70%" : "30%")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MobileVizcomName = styled.p`
  @media ${devices.mobile} {
    font-size: 30px;
    font-weight: 1000;
    display: flex;
  }

  @media ${devices.tablet} {
    font-size: 50px;
    font-weight: 1000;
    letter-spacing: 2px;
    display: flex;
  }
`;

export const MobileTagline = styled.p`
  @media ${devices.mobile} {
    display: flex;
    font-size: 17px;
    font-style: italic;
    font-weight: 700;
    color: #0021ff;
  }

  @media ${devices.tablet} {
    display: flex;
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    color: #0021ff;
  }
`;

// BANNER STYLES
export const BannerContainer = styled.div`
  @media ${devices.mobile} {
    display: none;
  }

  @media ${devices.tablet} {
    display: none;
  }

  @media ${devices.laptopS} {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 50px 0 50px 0;
    position: relative;
    height: 700px;
    padding: 20px;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const BannerCon = styled.div`
  @media ${devices.mobile} {
    display: none;
  }

  @media ${devices.tablet} {
    display: none;
  }

  @media ${devices.laptopS} {
    height: 90%;
    width: 100%;
    display: flex;
    position: relative;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const VizName = styled.img`
  width: 25%;
`;

export const CorporationContainer = styled.div`
  height: 90%;
  width: 65%;
  background-color: #fdc500;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-top-right-radius: 300px;
  border-bottom-right-radius: 300px;
  z-index: 1;
  padding: 20px 25px;
`;

export const CorpoName = styled.img`
  width: 60%;
`;

export const Tagline = styled.p`
  font-size: 38px;
  font-style: italic;
  font-weight: 700;
  color: #0021ff;
  margin-left: 5px;
`;

export const Greetings = styled.p`
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  color: #2c2b2b;
  margin: 30px 0 0 10px;
  line-height: 30px;
  padding-right: 140px;
`;

export const OwnerName = styled.p`
  font-size: 20px;
  font-weight: 700;
  margin: 50px 0 0 10px;
`;

export const OwnerPosition = styled.p`
  font-size: 20px;
  font-weight: 400;
  margin-left: 10px;
  color: #2c2b2b;
`;

export const BannerPhotoContainer = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  position: absolute;
  right: 0;
  top: -80px;
`;

export const CirOne = styled.img`
  height: 100%;
  position: absolute;
`;

export const CirTwo = styled.img`
  height: 90%;
  position: absolute;
  color: #2c2b2b;
`;

export const CirThree = styled.img`
  height: 58%;
  position: absolute;
  left: 37px;
  top: 160px;
  z-index: 1;
`;

export const PhotoCon = styled.div`
  height: 89%;
  width: 81%;
  z-index: 1;
  position: absolute;
  top: 32px;
  left: 39px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BannerPhoto = styled.img`
  height: 90%;
  z-index: 1;
`;

// MOBILE HI THERE STYLES
export const MobileWholeHiThereCon = styled.div`
  @media ${devices.mobile} {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    height: 600px;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    height: 500px;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    display: none;
  }

  @media ${devices.laptopL} {
    display: none;
  }
`;

export const MobileMapContainer = styled.div`
  width: 95vw;
  height: 50%;
  position: relative;
`;

export const MobileMapImage = styled.img`
  width: 100%;
  position: absolute;
`;

export const MobileHiThereTitle = styled.p`
  font-size: 30px;
  font-weight: 700;
  color: #f7d210;
  text-align: right;
  padding-right: 10px;
`;

export const MobileHiThereMessage = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #2c2b2b;
  text-align: right;
  padding-right: 10px;
`;

export const MobileImagesContainer = styled.div`
  width: 95vw;
  height: 50%;
  display: flex;
  position: relative;
  margin-left: 10px;
`;

export const MobileImagesLeftContainer = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const MobileLongImageContainer = styled.div`
  width: 40%;
  height: 100%;
  transition: 0.5s;

  &:hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    z-index: 1;
  }
`;

export const MobileTwoImagesContainer = styled.div`
  width: 58%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MobileTwoUpperContainer = styled.div`
  width: 100%;
  height: 40%;
`;

export const MobileTwoUpperImage = styled.img`
  width: 80%;
  height: 100%;
  transition: 0.5s;
  border-top-right-radius: 100%;

  &:hover {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    z-index: 1;
  }
`;

export const MobileTwoBottomContainer = styled.div`
  width: 100%;
  height: 58%;
`;

export const MobileTwoBottomImage = styled.img`
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 130%;
  transition: 0.5s;

  &:hover {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
    z-index: 1;
  }
`;

export const MobileImagesRightContainer = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const MobileElipsisContainer = styled.div`
  height: 140px;
  width: 140px;
  position: relative;
  z-index: 0;
`;

export const MobileElipsisOne = styled.img`
  width: 100%;
  position: absolute;
`;

export const MobileElipsisTwo = styled.img`
  width: 70%;
  position: absolute;
  top: 15%;
  left: 15%;
`;
export const MobileElipsisThree = styled.img`
  width: 40%;
  position: absolute;
  top: 30%;
  left: 30%;
`;
export const MobileElipsisFour = styled.img`
  width: 20%;
  position: absolute;
  top: 40%;
  left: 40%;
`;
export const MobileElipsisFive = styled.img`
  width: 10%;
  position: absolute;
  top: 45%;
  left: 45%;
`;
export const MobileElipsisSix = styled.img`
  width: 4%;
  position: absolute;
  top: 48%;
  left: 48%;
`;
// HI THERE STYLES
export const WholeHiThereCon = styled.div`
  @media ${devices.mobile} {
    display: none;
  }

  @media ${devices.tablet} {
    display: none;
  }

  @media ${devices.laptopS} {
    height: 90vh;
    padding: 40px;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const ImagesContainer = styled.div`
  width: 40%;
  height: 100%;
`;

export const LongImageContainer = styled.div`
  width: 35%;
  height: 100%;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    position: absolute;
    top: 0;
    width: 95%;
    z-index: 2;
  }
`;

export const LongImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  &:hover {
    border-radius: 20px;
    object-fit: cover;
  }
`;

export const UpperImageContainer = styled.div`
  width: 20%;
  height: 35%;
  transition: 0.5s;
  position: absolute;
  top: 5%;
  left: 17%;
  cursor: pointer;
  z-index: 1;

  &:hover {
    width: 95%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 40px;
  }
`;

export const UpperImage = styled.img`
  width: 100%;
  height: 100%;
  border-top-right-radius: 100%;

  &:hover {
    border-top-right-radius: 0%;
    border-radius: 20px;
    object-fit: cover;
  }
`;

export const BottomImageContainer = styled.div`
  width: 30%;
  height: 54%;
  transition: 0.5s;
  position: absolute;
  bottom: 5%;
  left: 17%;
  cursor: pointer;
  border-bottom-right-radius: 100%;
  z-index: 1;

  &:hover {
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 95%;
    height: 100%;
    left: 40px;
  }
`;

export const BottomImage = styled.img`
  height: 100%;
  width: 100%;
  border-bottom-right-radius: 100%;

  &:hover {
    border-bottom-right-radius: 0;
    border-radius: 20px;
    object-fit: cover;
  }
`;

export const HiThereCon = styled.div`
  width: 60%;
  display: flex;
  position: relative;
`;

export const MapImageContainer = styled.div`
  width: 100%;
  height: 500px;
  position: absolute;
`;

export const MapImage = styled.img`
  width: 100%;
`;

export const HiThereMessageContainer = styled.div`
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 20%;
`;

export const HiThereTitle = styled.p`
  font-size: 50px;
  font-weight: 700;
  color: #f7d210;
  text-align: right;
  cursor: pointer;
  z-index: 1;
  transition: 0.7s;

  &:hover {
    color: #0021ff;
    font-size: 70px;
  }
`;

export const HiThereMessage = styled.p`
  font-size: 17px;
  font-weight: 400;
  color: #2c2b2b;
  text-align: right;
  letter-spacing: 1px;
`;

export const ElipsisContainer = styled.div`
  height: 300px;
  width: 300px;
  position: relative;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -3;
`;

export const ElipsisOne = styled.img`
  height: 100%;
  position: absolute;
`;

export const ElipsisTwo = styled.img`
  height: 80%;
  position: absolute;
`;

export const ElipsisThree = styled.img`
  height: 60%;
  position: absolute;
`;

export const ElipsisFour = styled.img`
  height: 40%;
  position: absolute;
`;

export const LocationImage = styled.img`
  height: 20%;
  position: absolute;
  z-index: -1;
`;

// ABOUT US STYLES
export const AboutUsCon = styled.div`
  @media ${devices.mobile} {
    display: flex;
    max-width: 450px;
    height: 500px;
    margin-top: 50px;
    position: relative;
  }

  @media ${devices.tablet} {
    display: flex;
    max-width: 800px;
    height: 300px;
    margin-top: 50px;
    position: relative;
  }

  @media ${devices.laptopS} {
    max-width: 1366px;
    height: 600px;
    padding: 40px;
    position: relative;
  }

  @media ${devices.laptopL} {
    max-width: 100%;
    height: 600px;
    padding: 40px;
    position: relative;
  }
`;

export const CircleContainer = styled.div`
  height: 150%;
  width: 60%;
  position: relative;
  position: absolute;
  left: 3%;
  top: -30%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;

  @media ${devices.mobile} {
    display: none;
  }

  @media ${devices.tablet} {
    display: none;
  }

  @media ${devices.laptopS} {
    display: flex;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const ElipsisFive = styled.img`
  height: 30%;
  position: absolute;
`;

export const ElipsisSix = styled.img`
  height: 20%;
  position: absolute;
`;

export const ElipsisSeven = styled.img`
  height: 10%;
  position: absolute;
`;

export const BlueLineContainer = styled.div`
  width: 65%;
  background-color: white;
  position: absolute;
  right: 0;
  top: -10%;
  border: 5px solid #00509d;
  border-right: none;
  border-radius: 290px 0 0 290px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media ${devices.mobile} {
    width: 97%;
    height: 90%;
    position: absolute;
    top: 0;
    padding: 20px 0 35px 85px;
    border-radius: 180px 0 0 180px;
  }

  @media ${devices.tablet} {
    width: 90%;
    position: absolute;
    top: 0;
    padding: 20px 0 20px 95px;
    border-radius: 200px 0 0 200px;
  }

  @media ${devices.laptopS} {
    display: flex;
    width: 65%;
    border-radius: 250px 0 0 250px;
  }

  @media ${devices.laptopL} {
    display: flex;
    width: 65%;
  }
`;

export const AboutUsTitle = styled.p`
  @media ${devices.mobile} {
    font-size: 19px;
    font-weight: 700;
    color: #f7d210;
  }

  @media ${devices.tablet} {
    font-size: 25px;
    font-weight: 700;
    color: #f7d210;
  }

  @media ${devices.laptopS} {
    font-size: 50px;
    font-weight: 700;
    color: #f7d210;
    transition: 0.5s;

    &:hover {
      color: #0021ff;
      font-size: 80px;
    }
  }

  @media ${devices.laptopL} {
    font-size: 50px;
    font-weight: 700;
    color: #f7d210;
    transition: 0.5s;

    &:hover {
      color: #0021ff;
      font-size: 80px;
    }
  }
`;

export const AboutMessage = styled.p`
  @media ${devices.mobile} {
    font-size: 12px;
    font-weight: 400;
    text-align: right;
  }

  @media ${devices.tablet} {
    font-size: 14px;
    font-weight: 400;
    text-align: right;
  }

  @media ${devices.laptopS} {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #000000;
    margin-top: 10px;
    letter-spacing: 1px;
  }

  @media ${devices.laptopL} {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #000000;
    margin-top: 10px;
    letter-spacing: 1px;
  }
`;

// MISSION AND VISION STYLES
export const MissionVisionCon = styled.div`
  max-width: 100%;
  height: 660px;
  padding: 40px;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 150px;

  @media ${devices.mobile} {
    max-width: 450px;
    height: 800px;
    flex-direction: column-reverse;
    margin-top: 30px;
    padding: 0;
  }

  @media ${devices.tablet} {
    max-width: 800px;
    height: 800px;
    padding: 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  @media ${devices.laptopS} {
    max-width: 1366px;
    height: 660px;
    padding: 40px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 150px;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const MissionCon = styled.div`
  width: 54%;
  border: 5px solid #00509d;
  border-left: none;
  border-bottom: none;
  border-top-right-radius: 5%;
  padding: 40px;
  position: relative;

  @media ${devices.mobile} {
    display: flex;
    width: 100%;
    height: 55%;
    padding: 10px;
    margin-bottom: 30px;
  }

  @media ${devices.tablet} {
    display: flex;
    width: 100%;
    height: 55%;
    padding: 10px;
  }

  @media ${devices.laptopS} {
    width: 54%;
    border: 5px solid #00509d;
    border-left: none;
    border-bottom: none;
    border-top-right-radius: 5%;
    padding: 40px;
    position: relative;
    height: 100%;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const SmallBlue = styled.div`
  border: 5px solid #00509d;
  width: 20%;
  height: 20%;
  border-bottom-left-radius: 20%;
  border-top: none;
  border-right: none;
  position: absolute;
  bottom: 0;

  @media ${devices.mobile} {
    position: absolute;
    bottom: 0;
    left: 5px;
  }

  @media ${devices.tablet} {
    position: absolute;
    bottom: 0;
    left: 5px;
  }

  @media ${devices.laptopS} {
    border: 5px solid #00509d;
    width: 20%;
    height: 20%;
    border-bottom-left-radius: 20%;
    border-top: none;
    border-right: none;
    position: absolute;
    bottom: 0;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const MissionContainer = styled.div`
  width: 85%;
  height: 85%;
  position: absolute;
  right: 5%;
  border-radius: 3%;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  padding: 10px;

  @media ${devices.mobile} {
    width: 86%;
    height: 90%;
  }

  @media ${devices.tablet} {
    width: 86%;
  }

  @media ${devices.laptopS} {
    width: 85%;
    height: 85%;
    position: absolute;
    right: 5%;
    border-radius: 3%;
    box-shadow: 0px 4px 4px 0px #00000040 inset;
    padding: 10px;
  }

  @media ${devices.laptopL} {
    display: flex;
    flex-direction: column;
  }
`;

export const MissionItemsContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 5px 0;

  @media ${devices.mobile} {
    width: 100%;
    display: flex;
  }

  @media ${devices.tablet} {
    width: 100%;
    display: flex;
  }

  @media ${devices.laptopS} {
    width: 100%;
    display: flex;
    margin: 5px 0;
  }

  @media ${devices.laptopL} {
    width: 100%;
    display: flex;
    margin: 5px 0;
  }
`;

export const MissionMessageContainer = styled.div`
  @media ${devices.mobile} {
    width: 90%;
    height: 100%;
  }

  @media ${devices.tablet} {
    width: 90%;
    height: 100%;
  }

  @media ${devices.laptopS} {
    width: 80%;
    height: 100%;
  }

  @media ${devices.laptopL} {
    width: 80%;
    height: 100%;
  }
`;

export const MissionMessage = styled.p`
  @media ${devices.mobile} {
    font-size: 16px;
    font-weight: 400;
    text-align: right;
    color: #585858;
  }

  @media ${devices.tablet} {
    font-size: 19px;
    font-weight: 400;
    text-align: right;
    color: #585858;
  }

  @media ${devices.laptopS} {
    font-size: 20px;
    font-weight: 400;
    text-align: right;
    color: #585858;
    letter-spacing: 2px;
  }

  @media ${devices.laptopL} {
    font-size: 22px;
    font-weight: 400;
    text-align: right;
    color: #585858;
    letter-spacing: 2px;
  }
`;

export const MissionNumberContainer = styled.div`
  @media ${devices.mobile} {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  @media ${devices.laptopL} {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }
`;

export const MissionNumber = styled.p`
  @media ${devices.mobile} {
    font-size: 20px;
    font-weight: 700;
  }

  @media ${devices.tablet} {
    font-size: 20px;
    font-weight: 700;
  }

  @media ${devices.laptopS} {
    font-size: 40px;
    font-weight: 700;
    color: #f7d210;
  }

  @media ${devices.laptopL} {
    font-size: 40px;
    font-weight: 700;
    color: #f7d210;
  }
`;

export const MissionTitle = styled.p`
  @media ${devices.mobile} {
    font-size: 22px;
    font-weight: 700;
    position: absolute;
    letter-spacing: 20px;
    bottom: -4%;
    right: 1%;
    color: #f7d210;
  }

  @media ${devices.tablet} {
    font-size: 30px;
    font-weight: 700;
    position: absolute;
    letter-spacing: 20px;
    bottom: -4%;
    right: 1%;
    color: #f7d210;
  }

  @media ${devices.laptopS} {
    font-size: 40px;
    font-weight: 700;
    position: absolute;
    letter-spacing: 25px;
    bottom: -4%;
    right: 8%;
    color: #f7d210;
    transition: 0.5s;

    &:hover {
      color: #0021ff;
      font-size: 70px;
      bottom: -8%;
      right: 3%;
    }
  }

  @media ${devices.laptopL} {
    font-size: 48px;
    font-weight: 700;
    position: absolute;
    letter-spacing: 25px;
    bottom: -4%;
    right: 8%;
    color: #f7d210;
    transition: 0.5s;

    &:hover {
      color: #0021ff;
      font-size: 70px;
      bottom: -8%;
      right: 3%;
    }
  }
`;

export const VisionCon = styled.div`
  width: 44%;
  height: 65%;
  border: 5px solid #00509d;
  border-bottom-left-radius: 8%;
  border-top: none;
  border-right: none;
  margin-top: -2%;
  padding: 40px;
  position: relative;
  background-color: white;
  z-index: 1;

  @media ${devices.mobile} {
    display: flex;
    width: 100%;
    height: 25%;
    padding: 10px;
    margin: 0;
  }

  @media ${devices.tablet} {
    display: flex;
    width: 100%;
    height: 25%;
    padding: 10px;
    margin: 0;
  }

  @media ${devices.laptopS} {
    width: 44%;
    height: 65%;
    border: 5px solid #00509d;
    border-bottom-left-radius: 8%;
    border-top: none;
    border-right: none;
    margin-top: -2%;
    padding: 40px;
    position: relative;
    background-color: white;
    z-index: 1;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const SmallRightBlue = styled.div`
  border: 5px solid #00509d;
  width: 25%;
  height: 35%;
  border-top-right-radius: 20%;
  border-bottom: none;
  border-left: none;
  position: absolute;
  top: 7%;
  right: 5%;
`;

export const VisionContainer = styled.div`
  width: 80%;
  height: 60%;
  position: absolute;
  top: 22%;
  left: 7%;
  border-radius: 3%;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  padding: 40px;

  @media ${devices.mobile} {
    width: 80%;
    height: 70%;
    padding: 20px;
  }

  @media ${devices.tablet} {
    width: 80%;
    height: 70%;
    padding: 20px;
  }

  @media ${devices.laptopS} {
    width: 80%;
    height: 60%;
    position: absolute;
    top: 22%;
    left: 7%;
    border-radius: 3%;
    box-shadow: 0px 4px 4px 0px #00000040 inset;
    padding: 40px;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const VisionMessage = styled.p`
  @media ${devices.mobile} {
    font-size: 22px;
    font-weight: 400;
    color: #585858;
  }

  @media ${devices.tablet} {
    font-size: 22px;
    font-weight: 400;
    color: #585858;
  }

  @media ${devices.laptopS} {
    font-size: 30px;
    font-weight: 400;
    color: #585858;
    letter-spacing: 3px;
  }

  @media ${devices.laptopL} {
    font-size: 30px;
    font-weight: 400;
    color: #585858;
    letter-spacing: 3px;
  }
`;

export const VisionTitle = styled.p`
  @media ${devices.mobile} {
    font-size: 30px;
    font-weight: 700;
    color: #585858;
    letter-spacing: 20px;
    position: absolute;
    top: 2px;
    color: #f7d210;
  }

  @media ${devices.tablet} {
    font-size: 30px;
    font-weight: 700;
    color: #585858;
    letter-spacing: 20px;
    position: absolute;
    top: 2px;
    color: #f7d210;
  }

  @media ${devices.laptopS} {
    font-size: 40px;
    font-weight: 700;
    position: absolute;
    letter-spacing: 20px;
    top: 0;
    left: 8%;
    color: #f7d210;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      color: #0021ff;
      font-size: 70px;
      top: -8%;
      left: 3%;
    }
  }

  @media ${devices.laptopL} {
    font-size: 48px;
    font-weight: 700;
    position: absolute;
    letter-spacing: 25px;
    top: 0;
    left: 8%;
    color: #f7d210;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      color: #0021ff;
      font-size: 70px;
      top: -8%;
      left: 3%;
    }
  }
`;

export const ThirdElipsisContainer = styled.div`
  height: 80%;
  width: 40%;
  position: absolute;
  right: 3.5%;
  bottom: 3%;
  z-index: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${devices.mobile} {
    height: 250px;
    width: 250px;
    position: absolute;
    top: 10%;
    right: 6%;
  }

  @media ${devices.tablet} {
    height: 250px;
    width: 250px;
    position: absolute;
    top: 10%;
    right: 6%;
  }

  @media ${devices.laptopS} {
    height: 80%;
    width: 40%;
    position: absolute;
    right: 3.5%;
    bottom: 3%;
    z-index: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

// MEET THE TEAM STYLES
export const MeetTeamContainer = styled.div`
  @media ${devices.mobile} {
    max-width: 450px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  @media ${devices.tablet} {
    max-width: 800px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  @media ${devices.laptopS} {
    max-width: 1366px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    position: relative;
  }

  @media ${devices.laptopL} {
    max-width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    position: relative;
  }
`;

export const MeetTeamCon = styled.img`
  @media ${devices.mobile} {
    width: 98%;
    height: 95%;
    z-index: 0;
    position: absolute;
  }

  @media ${devices.tablet} {
    width: 98%;
    height: 95%;
    z-index: 0;
    position: absolute;
  }

  @media ${devices.laptopS} {
    width: 99%;
    height: 100%;
    z-index: 0;
  }

  @media ${devices.laptopL} {
    width: 99%;
    height: 100%;
    z-index: 0;
  }
`;

export const MeetUpperImage = styled.img`
  @media ${devices.mobile} {
    width: 70%;
    position: absolute;
    bottom: 13px;
    left: 17%;
  }

  @media ${devices.tablet} {
    width: 70%;
    position: absolute;
    bottom: 13px;
    left: 17%;
  }

  @media ${devices.laptopS} {
    position: absolute;
    width: 70%;
    bottom: -2%;
    left: 17%;
  }

  @media ${devices.laptopL} {
    /* width: 103%; */
    position: absolute;
    width: 70%;
    bottom: -2%;
    left: 17%;
  }
`;

export const MeetBottomImage = styled.img`
  @media ${devices.mobile} {
    position: absolute;
    width: 60%;
    bottom: 10px;
    left: 22%;
  }

  @media ${devices.tablet} {
    position: absolute;
    width: 60%;
    bottom: 10px;
    left: 22%;
  }

  @media ${devices.laptopS} {
    width: 103%;
    position: absolute;
    width: 60%;
    bottom: -4%;
    left: 22%;
  }

  @media ${devices.laptopL} {
    width: 103%;
    position: absolute;
    width: 60%;
    bottom: -4%;
    left: 22%;
  }
`;

export const CarouselItemsWrapper = styled.div`
  @media ${devices.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 250px;
    overflow: hidden;
    position: absolute;
    top: 2%;
  }

  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 400px;
    overflow: hidden;
    position: absolute;
    top: 2%;
  }

  @media ${devices.laptopS} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 500px;
    overflow: hidden;
    position: absolute;
    top: 2%;
  }

  @media ${devices.laptopL} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 500px;
    overflow: hidden;
    position: absolute;
    top: 2%;
  }
`;

export const MeetTitle = styled.p`
  @media ${devices.mobile} {
    font-size: 30px;
    font-weight: 700;
    color: #00509d;
  }

  @media ${devices.tablet} {
    font-size: 40px;
    font-weight: 700;
    color: #00509d;
    margin-top: 5px;
  }

  @media ${devices.laptopS} {
    font-size: 52px;
    font-weight: 700;
    color: #00509d;
  }

  @media ${devices.laptopL} {
    font-size: 52px;
    font-weight: 700;
    color: #00509d;
  }
`;

export const CarouselImageContainer = styled.div`
  @media ${devices.mobile} {
    height: 220px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media ${devices.tablet} {
    height: 350px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media ${devices.laptopS} {
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media ${devices.laptopL} {
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CarouselButton = styled.button`
  @media ${devices.mobile} {
    display: none;
  }

  @media ${devices.tablet} {
    display: flex;
    background-color: transparent;
    width: 60px;
    height: 50px;
    border: none;
  }

  @media ${devices.laptopS} {
    display: flex;
    background-color: transparent;
    width: 60px;
    height: 50px;
    border: none;
  }

  @media ${devices.laptopL} {
    display: flex;
    background-color: transparent;
    width: 60px;
    height: 50px;
    border: none;
  }
`;

export const PrevImage = styled.img`
  width: 90%;
  transform: rotate(180deg);
  opacity: 0.1;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const NextImage = styled.img`
  width: 90%;
  opacity: 0.1;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const CarouselItem = styled.div`
  width: ${(props) => (props.active ? "300px" : "100px")};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.8s ease-in-out;
  animation: ${(props) => (props.active ? "fadeIn" : "fadeOut")} 0.3s
    ease-in-out forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.5;
    }
  }

  img {
    width: ${(props) => (props.active ? "100%" : "90%")};
    object-fit: cover;
  }

  @media ${devices.mobile} {
    width: ${(props) => (props.active ? "250px" : "100px")};

    img {
      width: ${(props) => (props.active ? "100%" : "70%")};
      object-fit: cover;
    }
  }

  @media ${devices.tablet} {
    width: ${(props) => (props.active ? "250px" : "100px")};

    img {
      width: ${(props) => (props.active ? "100%" : "70%")};
      object-fit: cover;
    }
  }

  @media ${devices.laptopS} {
    width: ${(props) => (props.active ? "350px" : "200px")};
    img {
      width: ${(props) => (props.active ? "100%" : "90%")};
      object-fit: cover;
    }
  }

  @media ${devices.laptopL} {
    width: ${(props) => (props.active ? "350px" : "200px")};
    img {
      width: ${(props) => (props.active ? "100%" : "90%")};
      object-fit: cover;
    }
  }
`;

export const NamePositionContainer = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  @media ${devices.laptopS} {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  @media ${devices.laptopL} {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
`;

export const Name = styled.p`
  @media ${devices.mobile} {
    font-size: 20px;
    font-weight: 700;
    color: #00509d;
  }

  @media ${devices.tablet} {
    font-size: 30px;
    font-weight: 700;
    color: #00509d;
  }

  @media ${devices.laptopS} {
    font-size: 40px;
    font-weight: 700;
    color: #00509d;
    text-decoration: underline;
  }

  @media ${devices.laptopL} {
    font-size: 40px;
    font-weight: 700;
    color: #00509d;
    text-decoration: underline;
  }
`;

export const Position = styled.p`
  @media ${devices.mobile} {
    font-size: 14px;
    font-weight: 400;
    color: #2c2b2b;
  }

  @media ${devices.tablet} {
    font-size: 18px;
    font-weight: 400;
    color: #2c2b2b;
  }

  @media ${devices.laptopS} {
    font-size: 20px;
    font-weight: 400;
    color: #2c2b2b;
  }

  @media ${devices.laptopL} {
    font-size: 20px;
    font-weight: 400;
    color: #2c2b2b;
  }
`;

// OUR CORE VALUES STYLES
export const CoreValuesContainer = styled.div`
  @media ${devices.mobile} {
    max-width: 450px;
    height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media ${devices.tablet} {
    max-width: 800px;
    height: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media ${devices.laptopS} {
    max-width: 1366px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media ${devices.laptopL} {
    max-width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const WholeCoreTitle = styled.p`
  @media ${devices.mobile} {
    font-size: 30px;
    font-weight: 700;
    color: #f7d210;
  }

  @media ${devices.tablet} {
    font-size: 30px;
    font-weight: 700;
    color: #f7d210;
  }

  @media ${devices.laptopS} {
    font-size: 50px;
    font-weight: 700;
    padding: 10px 30px;
    color: #f7d210;
  }

  @media ${devices.laptopL} {
    font-size: 50px;
    font-weight: 700;
    padding: 10px 30px;
    color: #f7d210;
  }
`;

export const Container = styled.div`
  &:hover {
    .hover-container {
      display: flex;
    }
  }
`;

export const CoreWholeContainer = styled.div`
  @media ${devices.mobile} {
    width: 99%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  @media ${devices.tablet} {
    width: 99%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  @media ${devices.laptopS} {
    width: 99%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  @media ${devices.laptopL} {
    width: 99%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
`;

export const LeftContainer = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 20%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 20%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-items: center;
  }

  @media ${devices.laptopS} {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  @media ${devices.laptopL} {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;

export const CoreValueTitleContainer = styled(Container)`
  @media ${devices.mobile} {
    width: 90%;
    height: 80%;
    border-radius: 30px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 90%;
    height: 80%;
    border-radius: 30px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    width: 70%;
    height: 10%;
    border-radius: 30px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      background-color: #f7d210;
    }
  }

  @media ${devices.laptopL} {
    width: 70%;
    height: 10%;
    border-radius: 30px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      background-color: #f7d210;
    }
  }
`;

export const CoreValueTitle = styled.p`
  @media ${devices.mobile} {
    font-size: 14px;
    font-weight: 300;
    color: #00509d;
  }

  @media ${devices.tablet} {
    font-size: 14px;
    font-weight: 300;
    color: #00509d;
  }

  @media ${devices.laptopS} {
    font-size: 14px;
    font-weight: 300;
    color: #00509d;
  }

  @media ${devices.laptopL} {
    font-size: 14px;
    font-weight: 300;
    color: #00509d;
  }
`;

export const CoreItemContainer = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 20px;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    padding: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 20px;
  }

  @media ${devices.laptopS} {
    width: 80%;
    height: 100%;
    display: flex;
    padding: 10px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 20px;
  }

  @media ${devices.laptopL} {
    width: 80%;
    height: 100%;
    display: flex;
    padding: 10px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 20px;
  }
`;

export const CorePhotoContainer = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 50%;
  }

  @media ${devices.tablet} {
    width: 50%;
    height: 100%;
  }

  @media ${devices.laptopS} {
    width: 50%;
    height: 100%;
    display: flex;
  }

  @media ${devices.laptopL} {
    width: 50%;
    height: 100%;
    display: flex;
  }
`;

export const OrangeDivider = styled.div`
  @media ${devices.mobile} {
    display: none;
  }

  @media ${devices.tablet} {
    width: 20px;
    height: 100%;
    background-color: #f7d210;
    border-radius: 0 8px 8px 0;
    margin: 0 10px;
  }

  @media ${devices.laptopS} {
    width: 20px;
    height: 100%;
    background-color: #f7d210;
    border-radius: 0 8px 8px 0;
    margin: 0 10px;
  }

  @media ${devices.laptopL} {
    width: 20px;
    height: 100%;
    background-color: #f7d210;
    border-radius: 0 8px 8px 0;
    margin: 0 10px;
  }
`;

export const CoreCon = styled.div`
  @media ${devices.mobile} {
    width: 99%;
    height: 100%;
    padding: 5px;
  }

  @media ${devices.tablet} {
    width: 99%;
    height: 100%;
    padding: 5px;
  }

  @media ${devices.laptopS} {
    width: 100%;
    height: 100%;
  }

  @media ${devices.laptopL} {
    width: 100%;
    height: 100%;
  }
`;

export const CoreTitleContainer = styled.div`
  @media ${devices.mobile} {
    width: 99%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  @media ${devices.tablet} {
    width: 99%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  @media ${devices.laptopS} {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: -1;
  }

  @media ${devices.laptopL} {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: -1;
  }
`;

export const CoreTitle = styled.p`
  @media ${devices.mobile} {
    font-size: 30px;
    font-weight: 700;
    color: #00509d;
  }

  @media ${devices.tablet} {
    font-size: 30px;
    font-weight: 700;
    color: #00509d;
  }

  @media ${devices.laptopS} {
    font-size: 50px;
    font-weight: 700;
    color: #00509d;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  @media ${devices.laptopL} {
    font-size: 60px;
    font-weight: 700;
    color: #00509d;
    position: absolute;
    top: 0;
    z-index: 1;
  }
`;

export const CoreDetailContainer = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    width: 100%;
    height: 80%;
  }

  @media ${devices.laptopL} {
    width: 100%;
    height: 80%;
  }
`;

export const CoreDetail = styled.p`
  @media ${devices.mobile} {
    font-size: 14px;
    color: #393636;
    text-align: center;
  }

  @media ${devices.tablet} {
    font-size: 20px;
    color: #393636;
    text-align: center;
  }

  @media ${devices.laptopS} {
    font-size: 24px;
    font-weight: 400;
    color: #393636;
  }

  @media ${devices.laptopL} {
    font-size: 24px;
    font-weight: 400;
    color: #393636;
  }
`;

export const PhotoImg = styled.img`
  @media ${devices.mobile} {
    width: 99%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  @media ${devices.tablet} {
    height: 99%;
    border-radius: 20px;
  }

  @media ${devices.laptopS} {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  @media ${devices.laptopL} {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
`;

export const CoreHoverContainer = styled.div`
  @media ${devices.mobile} {
    display: none;
    width: 100%;
    height: 80%;
    background-color: white;
    position: absolute;
    left: 0;
    top: 20%;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #fdc500;
    align-items: center;
    justify-content: center;
    z-index: 9;
    padding: 10px;
    flex-direction: column;
  }

  @media ${devices.tablet} {
    display: none;
    width: 100%;
    height: 80%;
    background-color: white;
    position: absolute;
    left: 0;
    top: 20%;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #fdc500;
    align-items: center;
    justify-content: center;
    z-index: 9;
    padding: 10px;
    flex-direction: row;
  }

  @media ${devices.laptopS} {
    display: none;
    width: 80%;
    height: 100%;
    background-color: white;
    position: absolute;
    left: 20%;
    top: 0;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #fdc500;
    align-items: center;
    justify-content: center;
    z-index: 9;
    padding: 10px;
  }

  @media ${devices.laptopL} {
    display: none;
    width: 80%;
    height: 100%;
    background-color: white;
    position: absolute;
    left: 20%;
    top: 0;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #fdc500;
    align-items: center;
    justify-content: center;
    z-index: 9;
    padding: 10px;
  }
`;

// SPECIALIZATION STYLES
export const SpecializationContainer = styled.div`
  @media ${devices.mobile} {
    max-width: 450px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media ${devices.tablet} {
    max-width: 800px;
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media ${devices.laptopS} {
    max-width: 1366px;
    display: flex;
    height: 680px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media ${devices.laptopL} {
    max-width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const WholeSpecializationTitle = styled.p`
  @media ${devices.mobile} {
    font-size: 30px;
    font-weight: 700;
    color: #f7d210;
  }

  @media ${devices.tablet} {
    display: flex;
  }

  @media ${devices.laptopS} {
    font-size: 50px;
    font-weight: 700;
    padding: 20px;
    color: #f7d210;
  }

  @media ${devices.laptopL} {
    font-size: 50px;
    font-weight: 700;
    padding: 20px;
    color: #f7d210;
  }
`;

export const CenterContainer = styled.div`
  @media ${devices.mobile} {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 300px;
  }

  @media ${devices.tablet} {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 500px;
  }

  @media ${devices.laptopS} {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 550px;
  }

  @media ${devices.laptopL} {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 700px;
  }
`;

export const CarouselItemsWrapperOne = styled.div`
  @media ${devices.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    position: relative;
    overflow: hidden;
  }

  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    position: relative;
    overflow: hidden;
  }

  @media ${devices.laptopS} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    position: relative;
    overflow: hidden;
  }

  @media ${devices.laptopL} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
`;

export const CarouselItemOne = styled.div`
  width: ${(props) => (props.active ? "100%" : "0")};
  animation: ${(props) => (props.active ? "slideInLeft" : "slideOutLeft")} 0.9s
    ease-in-out forwards;

  @keyframes slideInLeft {
    from {
      transform: translateX(70%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOutLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  img {
    width: ${(props) => (props.active ? "100%" : "0")};
    height: ${(props) => (props.active ? "90vh" : "0")};
    object-fit: cover;
  }

  @media ${devices.mobile} {
    width: ${(props) => (props.active ? "100%" : "0")};
    img {
      width: ${(props) => (props.active ? "100%" : "0")};
      height: ${(props) => (props.active ? "90%" : "0")};
      object-fit: cover;
      border-radius: 20px;
    }
  }

  @media ${devices.tablet} {
    width: ${(props) => (props.active ? "100%" : "0")};
    img {
      width: ${(props) => (props.active ? "100%" : "0")};
      height: ${(props) => (props.active ? "90%" : "0")};
      object-fit: cover;
      border-radius: 20px;
    }
  }

  @media ${devices.laptopS} {
    width: ${(props) => (props.active ? "100%" : "0")};
    img {
      width: ${(props) => (props.active ? "100%" : "0")};
      height: ${(props) => (props.active ? "90%" : "0")};
      object-fit: cover;
      border-radius: 20px;
    }
  }

  @media ${devices.laptopL} {
    width: ${(props) => (props.active ? "100%" : "0")};
    img {
      width: ${(props) => (props.active ? "100%" : "0")};
      height: ${(props) => (props.active ? "100%" : "0")};
      object-fit: cover;
      border-radius: 20px;
    }
  }
`;

export const NamePositionContainerOne = styled.div`
  @media ${devices.mobile} {
    width: 80%;
    height: 70px;
    position: absolute;
    bottom: 40px;
    left: 10px;
    z-index: 90;
  }

  @media ${devices.tablet} {
    width: 97%;
    height: 70px;
    position: absolute;
    top: 70%;
    left: 20px;
    z-index: 90;
  }

  @media ${devices.laptopS} {
    width: 70%;
    position: absolute;
    z-index: 90;
    top: 70%;
    left: 5%;
    background-color: transparent;
  }

  @media ${devices.laptopL} {
    width: 50%;
    position: absolute;
    z-index: 90;
    bottom: 10%;
    left: 5%;
    background-color: transparent;
  }
`;

export const NameOne = styled.p`
  @media ${devices.mobile} {
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    text-shadow: 0px 4px 4px 0px #00000040;
  }

  @media ${devices.tablet} {
    font-size: 25px;
    font-weight: 700;
    color: #ffffff;
    text-shadow: 0px 4px 4px 0px #00000040;
  }

  @media ${devices.laptopS} {
    font-size: 50px;
    font-weight: 700;
    color: #ffffff;
    text-shadow: 0px 4px 4px 0px #00000040;
  }

  @media ${devices.laptopL} {
    font-size: 60px;
    font-weight: 700;
    color: #ffffff;
    text-shadow: 0px 4px 4px 0px #00000040;
  }
`;

export const PreviousButton = styled.button`
  @media ${devices.mobile} {
    display: none;
  }

  @media ${devices.tablet} {
    display: flex;
    background-color: transparent;
    width: 100px;
    height: 20%;
    border: none;
    z-index: 90;
    position: absolute;
    left: 0;
  }

  @media ${devices.laptopS} {
    display: flex;
    background-color: transparent;
    width: 100px;
    height: 20%;
    border: none;
    z-index: 90;
    position: absolute;
    left: 0;
  }

  @media ${devices.laptopL} {
    background-color: transparent;
    width: 100px;
    height: 20%;
    border: none;
    z-index: 90;
    position: absolute;
    left: 0;
  }
`;

export const PrevImageOne = styled.img`
  width: 50%;
  transform: rotate(180deg);
  opacity: 0.2;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const NextButton = styled.button`
  /* display: flex;
  background-color: transparent;
  width: 100px;
  height: 20%;
  border: none;
  z-index: 1;
  position: absolute;
  right: -4%; */

  @media ${devices.mobile} {
    display: none;
  }

  @media ${devices.tablet} {
    display: flex;
    background-color: transparent;
    width: 100px;
    height: 20%;
    border: none;
    z-index: 90;
    position: absolute;
    right: 0;
  }

  @media ${devices.laptopS} {
    display: flex;
    z-index: 90;
  }

  @media ${devices.laptopL} {
    display: flex;
    z-index: 90;
  }
`;

export const NextImageOne = styled.img`
  width: 50%;
  opacity: 0.2;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

// CLOUD BASED TRAINING FLATFORM STYLES
export const TrainingContainer = styled.div`
  height: 500px;
  width: 90%;
  border: 5px solid #00509d;
  border-left: none;
  border-radius: 0 600px 600px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  margin: 50px 0 50px 20px;

  @media ${devices.mobile} {
    max-width: 450px;
    border: 5px solid #00509d;
    border-left: none;
    border-radius: 0 100px 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px 0 0;
    background-color: transparent;
    margin-top: 0;
  }

  @media ${devices.tablet} {
    max-width: 800px;
    border: 5px solid #00509d;
    border-left: none;
    border-radius: 0 200px 200px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px 0 0;
    background-color: transparent;
  }

  @media ${devices.laptopS} {
    max-width: 1366px;
    width: 90%;
    border: 5px solid #00509d;
    border-left: none;
    border-radius: 0 600px 600px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 120px 50px 10px;
    margin: 50px 0 50px 20px;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const TrainingTitle = styled.p`
  font-size: 48px;
  font-weight: 700;
  color: #f7d210;
  margin-bottom: 40px;

  @media ${devices.mobile} {
    font-size: 22px;
    font-weight: 700;
    color: #f7d210;
    margin-bottom: 20px;
  }

  @media ${devices.tablet} {
    font-size: 30px;
    font-weight: 700;
    color: #f7d210;
    margin-bottom: 20px;
  }

  @media ${devices.laptopS} {
    font-size: 48px;
    font-weight: 700;
    color: #f7d210;
    margin-bottom: 40px;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const TrainingDetails = styled.p`
  font-size: 24px;
  font-weight: 400;
  color: #2c2b2b;
  text-align: center;

  @media ${devices.mobile} {
    font-size: 15px;
    font-weight: 400;
    color: #2c2b2b;
    text-align: left;
  }

  @media ${devices.tablet} {
    font-size: 20px;
    font-weight: 400;
    color: #2c2b2b;
    text-align: left;
  }

  @media ${devices.laptopS} {
    font-size: 24px;
    font-weight: 400;
    color: #2c2b2b;
    text-align: center;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

// CLIENT RELATION CONTAINER STYLES
export const WholeCLientContainer = styled.div`
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${devices.mobile} {
    max-width: 450px;
    height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    max-width: 800px;
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    max-width: 1366px;
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const WholeClientTitleCon = styled.div`
  width: 100%;

  @media ${devices.mobile} {
    width: 100%;
    margin-bottom: 20px;
  }

  @media ${devices.tablet} {
    width: 100%;
  }

  @media ${devices.laptopS} {
    width: 100%;
  }

  @media ${devices.laptopL} {
    width: 100%;
  }
`;

export const WholeClientTitle = styled.p`
  color: #f7d210;
  font-size: 48px;
  font-weight: 700;
  padding: 10px;

  @media ${devices.mobile} {
    color: #f7d210;
    font-size: 25px;
    font-weight: 700;
    padding: 10px;
  }

  @media ${devices.tablet} {
    color: #f7d210;
    font-size: 30px;
    font-weight: 700;
    padding: 10px;
  }

  @media ${devices.laptopS} {
    color: #f7d210;
    font-size: 48px;
    font-weight: 700;
    padding: 10px;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const ClientContainer = styled.div`
  width: 95%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${devices.mobile} {
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media ${devices.tablet} {
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media ${devices.laptopS} {
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const ClientItemsContainer = styled.div`
  height: 35%;
  width: 100%;
  box-shadow: 0px 4px 4px 1px #00000040;
  border-radius: 0 0 20px 20px;
  display: flex;
  position: relative;
  margin: 50px 0;

  @media ${devices.mobile} {
    /* height: 35%; */
    width: 100%;
    box-shadow: 0px 4px 4px 1px #00000040;
    border-radius: 0 0 20px 20px;
    display: flex;
    position: relative;
    margin: 45px 0;
  }

  @media ${devices.tablet} {
    width: 100%;
    box-shadow: 0px 4px 4px 1px #00000040;
    border-radius: 0 0 20px 20px;
    display: flex;
    position: relative;
    margin: 45px 0;
  }

  @media ${devices.laptopS} {
    height: 30%;
    width: 100%;
    box-shadow: 0px 4px 4px 1px #00000040;
    border-radius: 0 0 20px 20px;
    display: flex;
    flex-direction: row;
    position: relative;
    margin: 50px 0;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const ClientItemDetailContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;

  @media ${devices.mobile} {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
  }

  @media ${devices.laptopS} {
    width: 85%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const ClientItemTitle = styled.p`
  font-size: 32px;
  font-weight: 700;
  color: #00509d;

  @media ${devices.mobile} {
    font-size: 25px;
    font-weight: 700;
    color: #00509d;
  }

  @media ${devices.tablet} {
    font-size: 28px;
    font-weight: 700;
    color: #00509d;
  }

  @media ${devices.laptopS} {
    font-size: 32px;
    font-weight: 700;
    color: #00509d;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const ClientItemDetail = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: #3e3e3e;
  text-align: right;

  @media ${devices.mobile} {
    font-size: 14px;
    font-weight: 400;
    color: #3e3e3e;
    text-align: left;
    margin-top: 30px;
  }

  @media ${devices.tablet} {
    font-size: 16px;
    font-weight: 400;
    color: #3e3e3e;
    text-align: left;
    margin-top: 30px;
  }

  @media ${devices.laptopS} {
    font-size: 20px;
    font-weight: 400;
    color: #3e3e3e;
    text-align: right;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const ClientItemImageContainer = styled.div`
  @media ${devices.mobile} {
    height: 100px;
    width: 100px;
    position: absolute;
    top: -20%;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    height: 100px;
    width: 100px;
    position: absolute;
    top: -20%;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    height: 100px;
    width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 93%;
    top: -25%;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const ClientItemImage = styled.img`
  height: 97%;
`;

// FEEDBACK STYLES
export const FeedbackContainer = styled.div`
  @media ${devices.mobile} {
    max-width: 450px;
    height: 1000px;
  }

  @media ${devices.tablet} {
    max-width: 800px;
    height: 500px;
  }

  @media ${devices.laptopS} {
    max-width: 1366px;
    height: 600px;
  }

  @media ${devices.laptopL} {
    display: flex;
    flex-direction: column;
  }
`;

export const FeedWholeItemsContainer = styled.div`
  height: 90%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media ${devices.mobile} {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @media ${devices.tablet} {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  @media ${devices.laptopS} {
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const FeedbackTitle = styled.p`
  @media ${devices.mobile} {
    color: #f7d210;
    font-size: 30px;
    font-weight: 700;
    padding: 10px 0 20px 0;
    text-align: center;
  }

  @media ${devices.tablet} {
    color: #f7d210;
    font-size: 40px;
    font-weight: 700;
    padding: 10px 0 20px 0;
    text-align: center;
  }

  @media ${devices.laptopS} {
    color: #f7d210;
    font-size: 40px;
    font-weight: 700;
    padding: 10px 0 20px 0;
    text-align: center;
  }

  @media ${devices.laptopL} {
    color: #f7d210;
    font-size: 40px;
    font-weight: 700;
    padding: 10px 0 20px 0;
    text-align: center;
  }
`;

export const FeedbackItemContainer = styled.div`
  @media ${devices.mobile} {
    height: 48%;
    width: 99%;
    box-shadow: 1px 4px 4px 0px #00000080;
    border-radius: 20px;
  }

  @media ${devices.tablet} {
    height: 100%;
    width: 45%;
    box-shadow: 1px 4px 4px 0px #00000080;
    border-radius: 20px;
  }

  @media ${devices.laptopS} {
    height: 90%;
    width: 40%;
    box-shadow: 1px 4px 4px 0px #00000080;
    border-radius: 20px;
  }

  @media ${devices.laptopL} {
    display: flex;
    flex-direction: column;
  }
`;

export const FeedPhotoContainer = styled.div`
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FeedImageContaner = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 1px solid #00509d;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FeedUserPhoto = styled.img`
  height: 90%;
  width: 90%;
`;

export const FeedBottomContainer = styled.div`
  width: 100%;
  height: 60%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FeedUserName = styled.p`
  @media ${devices.mobile} {
    font-size: 25px;
    font-weight: 700;
    color: #00509d;
    text-align: center;
  }

  @media ${devices.tablet} {
    font-size: 32px;
    font-weight: 700;
    color: #00509d;
    text-align: center;
  }

  @media ${devices.laptopS} {
    font-size: 32px;
    font-weight: 700;
    color: #00509d;
    text-align: center;
  }

  @media ${devices.laptopL} {
    font-size: 32px;
    font-weight: 700;
    color: #00509d;
    text-align: center;
  }
`;

export const QuoteContainer = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const QuoteImage = styled.img`
  height: 40%;
`;

export const FeedMessage = styled.p`
  @media ${devices.mobile} {
    font-size: 14px;
    font-weight: 400;
    color: #2c2b2b;
  }

  @media ${devices.tablet} {
    font-size: 15px;
    font-weight: 400;
    color: #2c2b2b;
  }

  @media ${devices.laptopS} {
    font-size: 18px;
    font-weight: 400;
    color: #2c2b2b;
  }

  @media ${devices.laptopL} {
    font-size: 20px;
    font-weight: 400;
    color: #2c2b2b;
  }
`;

// FREQUENTLY ASKED QUESTIONS STYLES
export const WholeFAQContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${devices.mobile} {
    max-width: 450px;
    height: 730px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    max-width: 800px;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  @media ${devices.laptopS} {
    max-width: 1366px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const WholeFAQTitleContainer = styled.div`
  width: 100%;
`;

export const WholeFAQTitle = styled.p`
  font-size: 48px;
  font-weight: 700;
  color: #f7d210;
  padding: 20px 10px;

  @media ${devices.mobile} {
    font-size: 30px;
    font-weight: 700;
    color: #f7d210;
  }

  @media ${devices.tablet} {
    font-size: 30px;
    font-weight: 700;
    color: #f7d210;
  }

  @media ${devices.laptopS} {
    font-size: 48px;
    font-weight: 700;
    color: #f7d210;
    padding: 20px 10px;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const FAQMainContainer = styled.div`
  height: 90%;
  width: 95%;
  display: grid;
  grid-template-columns: 18% 18% 18% 18% 18%;
  justify-content: space-around;
  align-items: center;
  position: relative;

  @media ${devices.mobile} {
    height: 90%;
    width: 95%;
    display: grid;
    grid-template-columns: 43% 43%;
    justify-content: space-around;
    align-items: center;
    position: relative;
  }

  @media ${devices.tablet} {
    height: 90%;
    width: 95%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: space-around;
    align-items: center;
    position: relative;
  }

  @media ${devices.laptopS} {
    height: 90%;
    width: 95%;
    display: grid;
    grid-template-columns: 18% 18% 18% 18% 18%;
    justify-content: space-around;
    align-items: center;
    position: relative;
  }

  @media ${devices.laptopL} {
    display: grid;
    grid-template-columns: 18% 18% 18% 18% 18%;
  }
`;

export const FAQHoverContainer = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 1px 4px 4px 0px #00000080;
  background-color: #ffffff;
  border-radius: 20px;
`;

export const FAQCon = styled.div`
  &:hover {
    .FAQ-hover-con {
      display: flex;
    }
  }
`;

export const FAQItemContainer = styled(FAQCon)`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  box-shadow: 1px 4px 4px 0px #00000080;
  background-color: #ffffff;
  border-radius: 20px;
  cursor: pointer;
  padding: 10px;
  transition: 0.3s;

  &:hover {
    background-color: lightblue;
  }

  @media ${devices.mobile} {
    width: 100%;
    display: flex;
    align-items: center;
    box-shadow: 1px 4px 4px 0px #00000080;
    background-color: #ffffff;
    border-radius: 20px;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    box-shadow: 1px 4px 4px 0px #00000080;
    background-color: #ffffff;
    border-radius: 20px;
  }

  @media ${devices.laptopS} {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    box-shadow: 1px 4px 4px 0px #00000080;
    background-color: #ffffff;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px;
    transition: 0.3s;

    &:hover {
      background-color: lightblue;
    }
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const FAQItemTitle = styled.p`
  @media ${devices.mobile} {
    font-size: 13px;
    font-style: italic;
    font-weight: 700;
    color: #2c2b2b;
  }

  @media ${devices.tablet} {
    font-size: 16px;
    font-style: italic;
    font-weight: 700;
    color: #2c2b2b;
  }

  @media ${devices.laptopS} {
    font-size: 16px;
    font-style: italic;
    font-weight: 700;
    color: #2c2b2b;
  }

  @media ${devices.laptopL} {
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    color: #2c2b2b;
  }
`;

export const HoverFAQ = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.mobile} {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${devices.laptopS} {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const FAQHoverTitleContainer = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopL} {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FAQHoverTitle = styled.p`
  font-size: 35px;
  font-style: italic;
  font-weight: 700;
  color: #2c2b2b;

  @media ${devices.mobile} {
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    color: #2c2b2b;
  }

  @media ${devices.tablet} {
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    color: #2c2b2b;
  }

  @media ${devices.laptopS} {
    font-size: 25px;
    font-style: italic;
    font-weight: 700;
    color: #2c2b2b;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const FAQHoverHr = styled.hr`
  width: 90%;
  border: 1px solid #00509d;
  color: #00509d;
`;

export const FAQHoverAnswerContainer = styled.div`
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${devices.mobile} {
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media ${devices.tablet} {
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media ${devices.laptopS} {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;

export const FAQHoverAnswer = styled.p`
  font-size: 37px;
  font-weight: 300;
  color: #2c2b2b;

  @media ${devices.mobile} {
    font-size: 20px;
    font-weight: 300;
    color: #2c2b2b;
  }

  @media ${devices.tablet} {
    font-size: 25px;
    font-weight: 300;
    color: #2c2b2b;
  }

  @media ${devices.laptopS} {
    font-size: 20px;
    font-weight: 300;
    color: #2c2b2b;
  }

  @media ${devices.laptopL} {
    display: flex;
  }
`;
