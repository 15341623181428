import { devices } from "../Components/Devices";
import styled from "styled-components";

export const WholeLogInContainer = styled.div`
  @media ${devices.mobile} {
    max-width: 450px;
    height: calc(100vh - 55px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media ${devices.tablet} {
    max-width: 800px;
    height: calc(100vh - 55px);
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  @media ${devices.laptopS} {
    max-width: 1366px;
    height: calc(100vh - 65px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopL} {
    max-width: 100%;
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Main = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media ${devices.laptopS} {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  @media ${devices.laptopL} {
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const HalfMain = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopL} {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const LogInMessage = styled.p`
  @media ${devices.mobile} {
    font-size: 30px;
    font-weight: 700;
    color: #004bff;
    margin-top: 5px;
  }

  @media ${devices.tablet} {
    font-size: 30px;
    font-weight: 700;
    color: #004bff;
  }

  @media ${devices.laptopS} {
  }

  @media ${devices.laptopL} {
    font-size: 40px;
    font-weight: 700;
    color: #004bff;
    margin-bottom: 100px;
  }
`;

export const LogInInput = styled.input`
  @media ${devices.mobile} {
    font-size: 14px;
    padding: 10px 20px;
  }

  @media ${devices.tablet} {
    font-size: 14px;
    padding: 10px 20px;
    width: 60%;
    margin: 10px 0;
  }

  width: 70%;
  height: 50px;
  margin: 10px 0;
  border: none;
  border-radius: 20px;
  padding: 10px 30px;
  font-size: 18px;
  box-shadow: 0px 4px 4px 0px #1a66cc;

  &:focus {
    border: 1px solid #1a66cc;
    outline: none;
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

export const ForgetPass = styled.p`
  @media ${devices.mobile} {
    margin-bottom: 10px;
  }

  font-size: 16px;
  font-weight: 400;
  color: darkgray;
  margin-bottom: 50px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: black;
  }
`;

export const SignInButton = styled.button`
  @media ${devices.mobile} {
    padding: 10px;
  }

  width: 200px;
  height: 50px;
  background-color: #1a66cc;
  border: none;
  border-radius: 120px;
  cursor: pointer;
  font-size: 18px;
  color: white;
  transition: 0.3s;

  &:hover {
    background-color: lightblue;
    color: black;
  }

  &:active {
    background-color: #1a66cc;
    color: white;
  }
`;

export const OrHrContainer = styled.div`
  @media ${devices.mobile} {
    margin: 10px 0;
  }

  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 30px 0;
`;

export const OrText = styled.p`
  font-size: 14px;
  color: #7d7a7abf;
  margin: 0 15px;
`;

export const HrLine = styled.hr`
  @media ${devices.mobile} {
    width: 20%;
  }

  width: 40%;
  border: 1px solid #eaeae8;
`;

export const SocMedButtonCon = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SocMedButton = styled.button`
  width: 150px;
  height: 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;

  &:hover {
    background-color: lightblue;
    color: black;
  }
`;

export const SocMedImage = styled.img`
  height: 80%;
  margin-right: 10px;
`;

export const WelcomeBack = styled.p`
  @media ${devices.mobile} {
    font-size: 35px;
  }

  color: #004bff;
  font-size: 50px;
  font-weight: 700;
`;

export const WelcomePhotoContainer = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
  }

  @media ${devices.laptopL} {
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const WelcomePhoto = styled.img`
  height: 100%;
`;
