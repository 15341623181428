const sizes = {
  mobile: "100px",
  tablet: "451px",
  laptopS: "801px",
  laptopL: "1367px",
};

export const devices = {
  mobile: `(min-width: ${sizes.mobile})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptopS: `(min-width: ${sizes.laptopS})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
};
