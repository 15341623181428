import FooterPhoto from "../Images/Footer-Photo.png";
import FooterOrange from "../Images/Footer-Orange.png";
import FooterWhite from "../Images/Footer-White.png";
import FacebookIcon from "../Images/F-Facebook-Icon.png";
import LinkedInIcon from "../Images/F-Link-Icon.png";
import TwitterIcon from "../Images/F-Twitter-Icon.png";
import InstagramIcon from "../Images/F-Insta-Icon.png";
import mobFooter from "../Images/Mobile.png";

import {
  Boundless,
  Copyright,
  CopyrightContainer,
  FooterContactUs,
  FooterHalfCon,
  FooterLink,
  FooterModelImage,
  HalfContainer,
  MobileOrangeContainer,
  MobileOrangeImage,
  SilverHr,
  SocMed,
  SocMedContainer,
  SocMedHalfContainer,
  WholeFooterContainer,
} from "../Styles/Footer.Styled";

export default function Footer() {
  const facebookLink =
    "https://www.facebook.com/profile.php?id=100086389816868";
  const linkedInLink = "https://ph.linkedin.com/company/vizcom-corp";
  const twitterLink = "https://twitter.com/VizcomCorp";
  const instagramLink = "https://www.instagram.com/vizcomcorporation/";
  const googleForm = "http://bit.ly/3HqOFow.";
  const adminLink = "admin@vizcom.ph";

  return (
    <WholeFooterContainer>
      <MobileOrangeContainer>
        <FooterModelImage src={FooterPhoto} />
      </MobileOrangeContainer>
      <MobileOrangeContainer>
        <MobileOrangeImage src={mobFooter} />
        <FooterHalfCon>
          <HalfContainer>
            <SocMedContainer>
              <FooterLink>Add: Maharlika Highway, Bambang, N.V.</FooterLink>
              <a href={googleForm} target="blank" style={{textDecoration:'none'}}>
                <FooterLink >Email Add: admin@vizcom.ph</FooterLink>
              </a>
              <FooterLink>Contact Num: +63 956 448 4530</FooterLink>
            </SocMedContainer>
            <SocMedContainer>
              <SocMedHalfContainer>
                <a href={googleForm} target="blank">
                  <FooterContactUs>Apply Now</FooterContactUs>
                </a>
              </SocMedHalfContainer>
              <SocMedHalfContainer>
                <a href={facebookLink} target="blank">
                  <SocMed src={FacebookIcon} />
                </a>
                <a href={linkedInLink} target="blank">
                  <SocMed src={LinkedInIcon} />
                </a>
                <a href={twitterLink} target="blank">
                  <SocMed src={TwitterIcon} />
                </a>
                <a href={instagramLink} target="blank">
                  <SocMed src={InstagramIcon} />
                </a>
              </SocMedHalfContainer>
            </SocMedContainer>
          </HalfContainer>
          <HalfContainer>
            <CopyrightContainer>
              <Boundless>Discovering boundless opportunities!</Boundless>
              <SilverHr />
              <Copyright>
                2023 Vizcom Corporation. All Rights Reserved.
              </Copyright>
            </CopyrightContainer>
          </HalfContainer>
        </FooterHalfCon>
      </MobileOrangeContainer>
    </WholeFooterContainer>
  );
}
