import { useState, useEffect, useRef } from "react";

import {
  HomepageBody,
  WholeHomepageBody,
  BannerContainer,
  VizName,
  CorporationContainer,
  CorpoName,
  Tagline,
  Greetings,
  OwnerName,
  OwnerPosition,
  CirOne,
  CirTwo,
  CirThree,
  BannerPhotoContainer,
  BannerPhoto,
  WholeHiThereCon,
  ImagesContainer,
  LongImageContainer,
  LongImage,
  UpperImageContainer,
  UpperImage,
  BottomImageContainer,
  BottomImage,
  HiThereCon,
  MapImage,
  HiThereMessageContainer,
  HiThereTitle,
  HiThereMessage,
  ElipsisContainer,
  ElipsisOne,
  ElipsisTwo,
  ElipsisThree,
  ElipsisFour,
  LocationImage,
  AboutUsCon,
  CircleContainer,
  ElipsisFive,
  ElipsisSix,
  ElipsisSeven,
  BlueLineContainer,
  AboutUsTitle,
  AboutMessage,
  MissionVisionCon,
  MissionCon,
  SmallBlue,
  MissionContainer,
  MissionItemsContainer,
  MissionMessageContainer,
  MissionMessage,
  MissionNumberContainer,
  MissionNumber,
  MissionTitle,
  VisionCon,
  SmallRightBlue,
  VisionContainer,
  VisionMessage,
  VisionTitle,
  ThirdElipsisContainer,
  MeetTeamContainer,
  MeetTeamCon,
  MeetUpperImage,
  MeetBottomImage,
  CarouselItemsWrapper,
  MeetTitle,
  CarouselImageContainer,
  CarouselButton,
  PrevImage,
  NextImage,
  CarouselItem,
  NamePositionContainer,
  Name,
  Position,
  CoreValuesContainer,
  CoreItemContainer,
  WholeCoreTitle,
  CoreWholeContainer,
  CorePhotoContainer,
  PhotoImg,
  CoreDetail,
  CoreHoverContainer,
  LeftContainer,
  OrangeDivider,
  CoreCon,
  CoreTitleContainer,
  CoreTitle,
  CoreDetailContainer,
  CoreValueTitleContainer,
  CoreValueTitle,
  SpecializationContainer,
  CenterContainer,
  CarouselItemsWrapperOne,
  NextButton,
  PreviousButton,
  PrevImageOne,
  NextImageOne,
  CarouselItemOne,
  NamePositionContainerOne,
  NameOne,
  WholeSpecializationTitle,
  TrainingContainer,
  TrainingTitle,
  TrainingDetails,
  WholeCLientContainer,
  WholeClientTitle,
  ClientContainer,
  ClientItemsContainer,
  ClientItemDetailContainer,
  ClientItemImageContainer,
  ClientItemTitle,
  ClientItemDetail,
  ClientItemImage,
  FeedbackContainer,
  FeedbackTitle,
  FeedbackItemContainer,
  FeedPhotoContainer,
  FeedImageContaner,
  FeedUserPhoto,
  FeedWholeItemsContainer,
  FeedBottomContainer,
  FeedUserName,
  FeedMessage,
  QuoteContainer,
  QuoteImage,
  BannerCon,
  PhotoCon,
  WholeClientTitleCon,
  WholeFAQContainer,
  WholeFAQTitle,
  FAQMainContainer,
  FAQItemContainer,
  FAQItemTitle,
  FAQHoverContainer,
  FAQHoverTitleContainer,
  FAQHoverTitle,
  FAQHoverAnswerContainer,
  FAQHoverAnswer,
  HoverFAQ,
  FAQHoverHr,
  WholeFAQTitleContainer,
  MobileBannerCOntainer,
  MobileBannerCon,
  OrangeBackground,
  MobileBannerPhoto,
  OrangeCenterContainer,
  MobileGreetings,
  MobileOwnerName,
  MobileOwnerPosition,
  MobileBottomContainer,
  MobBottomHalfCon,
  MobileVizcomName,
  MobileRightHalfContainer,
  MobileCorpoName,
  MobileTagline,
  MapImageContainer,
  MobileWholeHiThereCon,
  MobileMapContainer,
  MobileMapImage,
  MobileHiThereTitle,
  MobileHiThereMessage,
  MobileImagesContainer,
  MobileImagesLeftContainer,
  MobileImagesRightContainer,
  MobileLongImageContainer,
  MobileTwoImagesContainer,
  MobileTwoUpperContainer,
  MobileTwoBottomContainer,
  MobileTwoUpperImage,
  MobileTwoBottomImage,
  MobileElipsisContainer,
  MobileElipsisOne,
  MobileElipsisTwo,
  MobileElipsisThree,
  MobileElipsisFour,
  MobileElipsisFive,
  MobileElipsisSix,
  CircleOneMobile,
  CircleTwoMobile,
  CircleThreeMobile,
} from "../Styles/Home.Styled";

// IMAGE IMPORT
import VizNameImage from "../Images/Vizcom-Name.png";
import CorpoImage from "../Images/Corp-Name.png";
import CirOneImage from "../Images/CircleOne.png";
import CirTwoImage from "../Images/CircleTwo.png";
import CirHalf from "../Images/HalfCircle.png";
import BannerImage from "../Images/Banner.png";
import LongImg from "../Images/paragliding.jfif";
import UpperImg from "../Images/parashoot.jfif";
import BottomImg from "../Images/house.jfif";
import Map from "../Images/map.png";
import ElOneImg from "../Images/CircleLightBlue.png";
import ElTwoImg from "../Images/CircleSkyBlue.png";
import ElThreeImg from "../Images/CircleDarkBlue.png";
import LocationImg from "../Images/Location.png";
import MaamJessica from "../Images/TeamRightOne.png";
import SirJeff from "../Images/teamBoss.png";
import SirPaul from "../Images/TeamLeftOne.png";
import MaamFaye from "../Images/TeamLeftTwo.png";
import MaamJhureen from "../Images/TeamLeftThree.png";
import MaamLaxsha from "../Images/TeamRightThree.png";
import MaamHazel from "../Images/TeamRightTwo.png";
import Next from "../Images/NextButton.png";
import MeetTeam from "../Images/MeetTheTeam.png";
import UpperCurve from "../Images/MeetUpper.png";
import BottomCurve from "../Images/MeetBottom.png";
import CommitmentPhoto from "../Images/Commitment.png";
import HonestyPhoto from "../Images/Honesty.png";
import InnovationPhoto from "../Images/Innovation.jfif";
import LoyaltyPhoto from "../Images/Loyalty.png";
import IntegrityPhoto from "../Images/Integrity.png";
import DiversityPhoto from "../Images/Diversity.png";
import EmphatyPhoto from "../Images/Emphaty.png";
import image1 from "../Images/Special1.png";
import image2 from "../Images/Special2.png";
import image3 from "../Images/Special3.png";
import image4 from "../Images/Special4.png";
import image5 from "../Images/Innovation.jfif";
import image6 from "../Images/Special6.png";
import image7 from "../Images/Special7.png";
import image8 from "../Images/Special8.png";
import image9 from "../Images/Special9.png";
import image10 from "../Images/Special3.png";
import image11 from "../Images/Special11.png";
import image12 from "../Images/Special12.png";
import image13 from "../Images/Special13.png";
import image14 from "../Images/Special14.png";
import image15 from "../Images/Special15.png";
import ClientOne from "../Images/client1.png";
import ClientTwo from "../Images/client2.png";
import ClientThree from "../Images/client3.png";
import UserOne from "../Images/UserOne.png";
import UserTwo from "../Images/UserTwo.png";
import Qoute from "../Images/Qoute.png";
import Footer from "../Components/Footer";
import Services from "./Services";

// BANNER MOBILE MODE
const MobileWholeBannerContainer = () => {
  return (
    <MobileBannerCOntainer id="headerone">
      <MobileBannerCon>
        <CircleOneMobile>
          <CircleTwoMobile>
            <CircleThreeMobile>
              <MobileBannerPhoto src={BannerImage} />
            </CircleThreeMobile>
          </CircleTwoMobile>
        </CircleOneMobile>
      </MobileBannerCon>
      <OrangeBackground>
        <OrangeCenterContainer>
          <MobileGreetings>
            Greetings from Vizcom Corporation, your homegrown partner in call
            center excellence, nestled amidst the breathtaking vistas of Nueva
            Vizcaya, Philippines! As we embark on our mission to redefine the
            BPO landscape, we extend a warm invitation to you, our future
            partners!
          </MobileGreetings>
          <MobileOwnerName>Engr. Jeferson Binay-an</MobileOwnerName>
          <MobileOwnerPosition>
            CEO, RMEE, Assoc ASEAN Engineer
          </MobileOwnerPosition>
        </OrangeCenterContainer>
        <MobileBottomContainer>
          <MobBottomHalfCon props="smaller">
            <MobileVizcomName>VIZCOM Corporation</MobileVizcomName>
          </MobBottomHalfCon>
          <MobBottomHalfCon>
            <MobileTagline>Discovering boundless opportunities!</MobileTagline>
          </MobBottomHalfCon>
        </MobileBottomContainer>
      </OrangeBackground>
    </MobileBannerCOntainer>
  );
};

// BANNER CONTAINER CODES
const WholeBanner = () => {
  return (
    <BannerContainer id="header">
      <VizName src={VizNameImage} />
      <BannerCon>
        <CorporationContainer>
          <CorpoName src={CorpoImage} />
          <Tagline>Discovering boundless opportunities!</Tagline>
          <Greetings>
            Greetings from Vizcom Corporation, your homegrown partner in call
            center excellence, nestled amidst the breathtaking vistas of Nueva
            Vizcaya, Philippines! As we embark on our mission to redefine the
            BPO landscape, we extend a warm invitation to you, our future
            partners!
          </Greetings>
          <OwnerName>Engr. Jeferson Binay-an</OwnerName>
          <OwnerPosition>CEO, RMEE, Assoc ASEAN Engineer</OwnerPosition>
        </CorporationContainer>
        <BannerPhotoContainer>
          <CirOne src={CirTwoImage} />
          <CirTwo src={CirOneImage} />
          <CirThree src={CirHalf} />
          <PhotoCon>
            <BannerPhoto src={BannerImage} />
          </PhotoCon>
        </BannerPhotoContainer>
      </BannerCon>
    </BannerContainer>
  );
};

// MOBILE HI THERE CONTAINER CODES
const MobileWholeHiThere = () => {
  return (
    <MobileWholeHiThereCon>
      <MobileMapContainer>
        <MobileMapImage src={Map} />
        <MobileHiThereTitle>Hi there!</MobileHiThereTitle>
        <MobileHiThereMessage>
          Welcome to Vizcom Corporation, a trusted partner in providing superb
          call center services in the charming and picturesque province of Nueva
          Vizcaya. As an innovative homegrown call center company.
          <br />
          <br />
          We are fully committed to providing unmatched customer service and
          cutting-edge solutions. We take enormous delight in our journey
          towards producing unique and unforgettable customer experiences, with
          an unrelenting commitment to excellence and an uninhibited zest for
          continuous improvement.
        </MobileHiThereMessage>
      </MobileMapContainer>
      <MobileImagesContainer>
        <MobileImagesLeftContainer>
          <MobileLongImageContainer>
            <LongImage src={LongImg} />
          </MobileLongImageContainer>
          <MobileTwoImagesContainer>
            <MobileTwoUpperContainer>
              <MobileTwoUpperImage src={UpperImg} />
            </MobileTwoUpperContainer>
            <MobileTwoBottomContainer>
              <MobileTwoBottomImage src={BottomImg} />
            </MobileTwoBottomContainer>
          </MobileTwoImagesContainer>
        </MobileImagesLeftContainer>
        <MobileImagesRightContainer>
          <MobileElipsisContainer>
            <MobileElipsisOne src={ElOneImg} />
            <MobileElipsisTwo src={ElOneImg} />
            <MobileElipsisThree src={ElOneImg} />
            <MobileElipsisFour src={ElOneImg} />
            <MobileElipsisFive src={ElOneImg} />
            <MobileElipsisSix src={ElOneImg} />
          </MobileElipsisContainer>
        </MobileImagesRightContainer>
      </MobileImagesContainer>
    </MobileWholeHiThereCon>
  );
};

// HI THERE CONTAINER CODES
const WholeHiThere = () => {
  return (
    <WholeHiThereCon>
      <ImagesContainer>
        <LongImageContainer>
          <LongImage src={LongImg} />
        </LongImageContainer>
        <UpperImageContainer>
          <UpperImage src={UpperImg} />
        </UpperImageContainer>
        <BottomImageContainer>
          <BottomImage src={BottomImg} />
        </BottomImageContainer>
      </ImagesContainer>
      <HiThereCon>
        <MapImageContainer>
          <MapImage src={Map} />
        </MapImageContainer>
        <HiThereMessageContainer>
          <HiThereTitle>Hi there!</HiThereTitle>
          <HiThereMessage>
            Welcome to Vizcom Corporation, a trusted partner in providing superb
            call center services in the charming and picturesque province of
            Nueva Vizcaya. As an innovative homegrown call center company.
            <br />
            <br /> We are fully committed to providing unmatched customer
            service and cutting-edge solutions. We take enormous delight in our
            journey towards producing unique and unforgettable customer
            experiences, with an unrelenting commitment to excellence and an
            uninhibited zest for continuous improvement.
          </HiThereMessage>
        </HiThereMessageContainer>
        <ElipsisContainer>
          <ElipsisOne src={ElOneImg} />
          <ElipsisTwo src={ElTwoImg} />
          <ElipsisThree src={ElThreeImg} />
          <ElipsisFour src={ElThreeImg} />
          <LocationImage src={LocationImg} />
        </ElipsisContainer>
      </HiThereCon>
    </WholeHiThereCon>
  );
};

// HI THERE CONTAINER CODES
const WholeAboutUs = () => {
  return (
    <AboutUsCon id="about">
      <CircleContainer>
        <ElipsisOne src={ElOneImg} />
        <ElipsisTwo src={ElOneImg} />
        <ElipsisFour src={ElOneImg} />
        <ElipsisFive src={ElOneImg} />
        <ElipsisSix src={ElTwoImg} />
        <ElipsisSeven src={ElOneImg} />
      </CircleContainer>
      <BlueLineContainer>
        <AboutUsTitle>About Us</AboutUsTitle>
        <AboutMessage>
          Vizcom Corporation is an innovative startup operating a dynamic call
          center in Nueva Vizcaya, Philippines. With a team boasting extensive
          expertise in the BPO sector and a 15-year collective experience in
          diverse corporate settings, Vizcom is committed to excellence in
          inbound and outbound customer service, live voice transcription,
          telemarketing, appointment-setting solutions, back office support,
          non-voice technical support are among others.
          <br />
          <br /> Central to our mission is aiding businesses in achieving their
          objectives through exceptional customer support, forging enduring
          partnerships with clients, and exceeding expectations. Vizcom's
          competent and knowledgeable personnel, equipped with innovative
          technology, ensure precise and effective call management. We
          prioritize investing in staff technical skills development to stay
          updated on industry trends and effective practices. Vizcom Corporation
          is dedicated to delivering outstanding results and outstanding service
          quality to its valued clients
        </AboutMessage>
      </BlueLineContainer>
    </AboutUsCon>
  );
};

// MISSION AND VISION CONTAINER CODES
const WholeMissionVision = () => {
  return (
    <MissionVisionCon>
      <MissionCon>
        <SmallBlue></SmallBlue>
        <MissionContainer>
          <MissionItemsContainer>
            <MissionMessageContainer>
              <MissionMessage>
                To deliver outstanding service andsupport to our clients,
                fostering economic growth in Nueva Vizcaya by creating
                meaningful job opportunities
              </MissionMessage>
            </MissionMessageContainer>
            <MissionNumberContainer>
              <MissionNumber>1</MissionNumber>
            </MissionNumberContainer>
          </MissionItemsContainer>
          <MissionItemsContainer>
            <MissionMessageContainer>
              <MissionMessage>
                To invest continuously in thedevelopment of our employees’
                skills and knowledge while also providing them with
                opportunities for career advancement and personal growth,
                thereby enhancing Nueva Vizcaya’s reputation as a hub of highly
                skilled and competent professionals
              </MissionMessage>
            </MissionMessageContainer>
            <MissionNumberContainer>
              <MissionNumber>2</MissionNumber>
            </MissionNumberContainer>
          </MissionItemsContainer>
          <MissionItemsContainer>
            <MissionMessageContainer>
              <MissionMessage>
                To position ourselves as a premier call center company within
                the BPO industry.
              </MissionMessage>
            </MissionMessageContainer>
            <MissionNumberContainer>
              <MissionNumber>3</MissionNumber>
            </MissionNumberContainer>
          </MissionItemsContainer>
        </MissionContainer>
        <MissionTitle>MISSION</MissionTitle>
      </MissionCon>
      <>
        <VisionCon>
          <SmallRightBlue></SmallRightBlue>
          <VisionContainer>
            <VisionMessage>
              A future-proof BPO Company enabling brands in the digital
              landscape.
            </VisionMessage>
          </VisionContainer>
          <VisionTitle>VISION</VisionTitle>
        </VisionCon>
        <ThirdElipsisContainer>
          <ElipsisOne src={ElOneImg} />
          <ElipsisTwo src={ElOneImg} />
          <ElipsisFour src={ElOneImg} />
          <ElipsisFive src={ElOneImg} />
          <ElipsisSeven src={ElOneImg} />
        </ThirdElipsisContainer>
      </>
    </MissionVisionCon>
  );
};

// MEET THE TEAM CONTAINER CODES
const imagesTeam = [
  {
    url: MaamJessica,
    name: "MS. JESSICA KENIO",
    position: "Consultant",
  },
  {
    url: SirJeff,
    name: "ENGR. JEFERSON BINAY - AN",
    position: "CEO, REMEE, Assoc ASEAN Engineer",
  },
  { url: SirPaul, name: "MR. ROGER PAUL BASSI", position: "Operations Head" },
  { url: MaamFaye, name: "MS. FAYE GONZALES", position: "Team Leader" },
  { url: MaamJhureen, name: "MS. JHUREEN FLORES", position: "Team Leader" },
  { url: MaamLaxsha, name: "MS. LAXSHAMI PAL", position: "HR Specialist" },
  { url: MaamHazel, name: "ATTY. HAZEL BACTAYON", position: "Legal Adviser" },
];

const WholeMeetTheTeam = () => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const intervalRef = useRef(null);

  const handleNext = () => {
    clearInterval(intervalRef.current);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imagesTeam.length - 1 : prevIndex - 1
    );
    startAutomaticNext();
  };

  const handlePrev = () => {
    clearInterval(intervalRef.current);
    setCurrentIndex((prevIndex) =>
      prevIndex === imagesTeam.length - 1 ? 0 : prevIndex + 1
    );
    startAutomaticNext();
  };

  const startAutomaticNext = () => {
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      handleNext();
    }, 3000);
  };

  useEffect(() => {
    startAutomaticNext();
    return () => clearInterval(intervalRef.current);
  }, []);

  const renderCarouselItems = () => {
    const items = [];

    for (let i = currentIndex + 3; i >= currentIndex - 3; i--) {
      let position = i;
      if (i < 0) position = imagesTeam.length + i;
      if (i >= imagesTeam.length) position = i % imagesTeam.length;

      const active = position === currentIndex;

      items.push(
        <CarouselItem
          key={i}
          active={active}
          style={{
            left: `${(currentIndex - i + 2) * 100}px`,
            zIndex: active ? imagesTeam.length : position,
          }}
        >
          <img src={imagesTeam[position].url} alt={`imagesTeam ${position}`} />
        </CarouselItem>
      );
    }

    return items;
  };
  return (
    <MeetTeamContainer>
      <MeetTeamCon src={MeetTeam} />
      <MeetUpperImage src={UpperCurve} />
      <MeetBottomImage src={BottomCurve} />
      <CarouselItemsWrapper>
        <MeetTitle>Meet the Team</MeetTitle>
        <CarouselImageContainer>
          <CarouselButton onClick={handlePrev}>
            <PrevImage src={Next} />
          </CarouselButton>
          {renderCarouselItems()}
          <CarouselButton onClick={handleNext}>
            <NextImage src={Next} />
          </CarouselButton>
        </CarouselImageContainer>
        <NamePositionContainer>
          <Name>{imagesTeam[currentIndex].name}</Name>
          <Position>{imagesTeam[currentIndex].position}</Position>
        </NamePositionContainer>
      </CarouselItemsWrapper>
    </MeetTeamContainer>
  );
};

// OUR CORE VALUES CONTAINERS
const coreValues = [
  {
    title: "COMMITMENT",
    details:
      "Vizcom's core value commitment is exemplified in our call center operations by our constant dedication to delivering exceptional service to every caller. We prioritize active listening, empathy, and swift issue resolution to ensure that each customer feels valued and heard, fostering long-lasting relationships and trust in our brand.",
    imageUrl: CommitmentPhoto,
  },
  {
    title: "HONESTY",
    details:
      "Honesty is one of the fundamental principles of Vizcom Corporation's core values. We uphold the highest ethical standards in all our interactions, promoting trust and openness within our company as well as in our interactions with our clients. Our steadfast commitment to honesty guarantees that we have integrity in our actions, decisions, and relationships, laying the platform for long-lasting partnerships and ethical business practices.",
    imageUrl: HonestyPhoto,
  },
  {
    title: "INNOVATION",
    details:
      "Innovation is the lifeblood of Vizcom Corporation, deeply embedded in our core values. We consistently extend beyond the boundaries of technology and creativity to create innovative solutions that empower our clients and enhance their visual communication endeavors. Our persistence in innovation propels us to stay at the forefront of the industry, delivering forward-thinking products and services that shape the future of visual communication.",
    imageUrl: InnovationPhoto,
  },
  {
    title: "LOYALTY",
    details:
      "Loyalty is a fundamental core value at Vizcom Corporation. We prioritize the trust and commitment of our clients, employees, and partners, establishing strong relationships built on mutual support and dedication. Our loyalty extends beyond business transactions, creating a sense of camaraderie and cooperation that drives us toward shared success and growth.",
    imageUrl: LoyaltyPhoto,
  },
  {
    title: "INTEGRITY",
    details:
      "Integrity lies at the heart of Vizcom Corporation's core values. We devote ourselves to the highest ethical standards, making certain that honesty, transparency, and trustworthiness guide every decision and action. Our adherence to integrity extends to our relationships with clients, partners, and employees, fostering an environment of strong ethical conduct and mutual respect.",
    imageUrl: IntegrityPhoto,
  },
  {
    title: "EXCELLENCE",
    details:
      "Excellence is at the heart of Vizcom Corporation's core values. We are committed to upholding the highest standards of quality in every aspect of our work, from product development to customer service. Our relentless pursuit of excellence ensures that we deliver outstanding solutions that meet and go beyond the expectations of our clients.",
    imageUrl: LoyaltyPhoto,
  },
  {
    title: "DIVERSITY",
    details:
      "We wholeheartedly embrace diversity as a core value. We believe that a diverse workforce fosters creativity, inclusivity, and a broader perspective, enabling us to better serve our world community and develop solutions that resonate with people from all walks of life. In our commitment to diversity, we strive to foster an environment where everyone's unique talents and backgrounds are valued and celebrated.",
    imageUrl: DiversityPhoto,
  },
  {
    title: "EMPHATY",
    details:
      "Empathy is one of the aspects of Vizcom Corporation's core values. We prioritize understanding and relating with the needs and emotions of our clients and colleagues. Through empathetic engagement, we not only provide solutions that truly resonate with our customers but also cultivate a supportive and compassionate work culture that promotes creativity and collaboration.",
    imageUrl: EmphatyPhoto,
  },
];

const WholeCoreValues = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % coreValues.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const coreValueTitles = coreValues.map((value, index) => (
    <CoreValueTitleContainer>
      <CoreValueTitle key={index}>{value.title}</CoreValueTitle>
      <CoreHoverContainer className="hover-container">
        <CorePhotoContainer>
          <PhotoImg src={value.imageUrl} />
        </CorePhotoContainer>
        <CorePhotoContainer>
          <OrangeDivider />
          <CoreCon>
            <CoreTitleContainer>
              <CoreTitle>{value.title}</CoreTitle>
            </CoreTitleContainer>
            <CoreDetailContainer>
              <CoreDetail>{value.details}</CoreDetail>
            </CoreDetailContainer>
          </CoreCon>
        </CorePhotoContainer>
      </CoreHoverContainer>
    </CoreValueTitleContainer>
  ));

  return (
    <CoreValuesContainer>
      <WholeCoreTitle>Our Core Values</WholeCoreTitle>
      <CoreWholeContainer>
        <LeftContainer>{coreValueTitles}</LeftContainer>
        <CoreItemContainer key={currentIndex}>
          <CorePhotoContainer>
            <PhotoImg src={coreValues[currentIndex].imageUrl} />
          </CorePhotoContainer>
          <CorePhotoContainer>
            <OrangeDivider />
            <CoreCon>
              <CoreTitleContainer>
                <CoreTitle>{coreValues[currentIndex].title}</CoreTitle>
              </CoreTitleContainer>
              <CoreDetailContainer>
                <CoreDetail>{coreValues[currentIndex].details}</CoreDetail>
              </CoreDetailContainer>
            </CoreCon>
          </CorePhotoContainer>
        </CoreItemContainer>
      </CoreWholeContainer>
    </CoreValuesContainer>
  );
};

// SPECIALIZATION CONTAINER CODES
const imagesSpecialization = [
  {
    url: image1,
    name: "Inbound Customer Support Experience",
  },
  {
    url: image2,
    name: "Food Order Processing Experience",
  },
  { url: image3, name: "Live Chat Support Experience" },
  { url: image4, name: "Social Media Monitoring" },
  { url: image5, name: "Technical Support Experience" },
  { url: image6, name: "Surveys and Feedback Experience" },
  { url: image7, name: "Email Response and Management Experience" },
  { url: image8, name: "Escalation Handling Experience" },
  { url: image9, name: "Cross-Selling and Upselling Experience" },
  { url: image10, name: "Data Entry and Data Verification" },
  { url: image11, name: "Market Research" },
  { url: image12, name: "Helpdesk Support" },
  { url: image13, name: "Appointment Setting" },
  { url: image14, name: "Virtual Receptionist Service" },
  { url: image15, name: "Technology, Workspace and Expandability" },
];

const WholeSpecialization = () => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const intervalRef = useRef(null);

  const handleNext = () => {
    clearInterval(intervalRef.current);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imagesSpecialization.length - 1 : prevIndex - 1
    );
    startAutomaticNext();
  };

  const handlePrev = () => {
    clearInterval(intervalRef.current);
    setCurrentIndex((prevIndex) =>
      prevIndex === imagesSpecialization.length - 1 ? 0 : prevIndex + 1
    );
    startAutomaticNext();
  };

  const startAutomaticNext = () => {
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      handleNext();
    }, 4000);
  };

  useEffect(() => {
    startAutomaticNext();
    return () => clearInterval(intervalRef.current);
  }, []);

  const renderCarouselItems = () => {
    const items = [];

    for (let i = currentIndex + 0; i >= currentIndex - 0; i--) {
      let position = i;
      if (i < 0) position = imagesSpecialization.length - i;
      if (i >= imagesSpecialization.length)
        position = i % imagesSpecialization.length;

      const active = position === currentIndex;

      items.push(
        <CarouselItemOne
          key={i}
          active={active}
          style={{
            left: `${(currentIndex - i + 2) * 100}px`,
            zIndex: active ? imagesSpecialization.length : position,
          }}
        >
          <img
            src={imagesSpecialization[position].url}
            alt={`Image ${position}`}
          />
        </CarouselItemOne>
      );
    }

    return items;
  };

  return (
    <SpecializationContainer id="blog">
      <WholeSpecializationTitle>Vizcom Specialization</WholeSpecializationTitle>
      <CenterContainer>
        <CarouselItemsWrapperOne>
          <PreviousButton onClick={handlePrev}>
            <PrevImageOne src={Next} />
          </PreviousButton>
          {renderCarouselItems()}
          <NamePositionContainerOne>
            <NameOne>{imagesSpecialization[currentIndex].name}</NameOne>
          </NamePositionContainerOne>
          <NextButton onClick={handleNext}>
            <NextImageOne src={Next} />
          </NextButton>
        </CarouselItemsWrapperOne>
      </CenterContainer>
    </SpecializationContainer>
  );
};

// TRAINING CONTAINER CODES
const WholeTraining = () => {
  return (
    <TrainingContainer>
      <TrainingTitle>Vizcom Cloud-based Training Platform</TrainingTitle>
      <TrainingDetails>
        Vizcom Corporation's standout product, Vizcom Academy, is a cutting-edge
        Cloud-Based Training Platform designed to elevate the skills of call
        center agents. This innovative platform offers comprehensive learning
        experiences, including video tutorials, interactive modules, and
        quizzes, accessible from anywhere in the world. Excitingly, Vizcom is
        integrating Artificial Intelligence-driven training simulations into
        Vizcom Academy, revolutionizing agent learning by providing real-life
        scenarios and virtual customer interactions for skills development in a
        risk-free environment. This commitment to technological advancement
        underscores Vizcom's dedication to delivering top-tier training
        solutions for exceptional customer experiences
      </TrainingDetails>
    </TrainingContainer>
  );
};

// CLIENT TRAINING CONTAINER CODES
const WholeClientTraining = () => {
  return (
    <WholeCLientContainer>
      <WholeClientTitleCon>
        <WholeClientTitle>
          Client Relations Management Platform
        </WholeClientTitle>
      </WholeClientTitleCon>
      <ClientContainer>
        <ClientItemsContainer>
          <ClientItemDetailContainer>
            <ClientItemTitle>Amazon Connect</ClientItemTitle>
            <ClientItemDetail>
              As a core component of our client relations strategy, Amazon
              Connect empowers us to provide a superior customer experience. Its
              cloud-based contact center solution allows us to efficiently
              manage customer interactions, ensure scalability, and deliver
              personalized support. With features like real-time analytics and
              integration capabilities, we gain valuable insights and adapt our
              services to meet evolving client needs promptly.
            </ClientItemDetail>
          </ClientItemDetailContainer>
          <ClientItemImageContainer>
            <ClientItemImage src={ClientOne} />
          </ClientItemImageContainer>
        </ClientItemsContainer>
        <ClientItemsContainer>
          <ClientItemDetailContainer>
            <ClientItemTitle>Ring Central</ClientItemTitle>
            <ClientItemDetail>
              Vizcom leverages Ring Central's comprehensive communication and
              collaboration platform to enhance client interactions. This tool
              not only facilitates efficient internal communication but also
              streamlines our external communications with clients. It ensures
              that our team is always accessible, enabling us to respond
              promptly to inquiries and provide real-time assistance.
            </ClientItemDetail>
          </ClientItemDetailContainer>
          <ClientItemImageContainer>
            <ClientItemImage src={ClientTwo} />
          </ClientItemImageContainer>
        </ClientItemsContainer>
        <ClientItemsContainer>
          <ClientItemDetailContainer>
            <ClientItemTitle>VICI Dialers</ClientItemTitle>
            <ClientItemDetail>
              VICI Dialers play a crucial role in optimizing our outbound
              communication strategies. This innovative platform automates and
              manages outbound calls, ensuring that our agents connect with
              clients effectively and efficiently. With features such as
              predictive dialing and call recording, we maintain the highest
              level of quality and compliance in our interactions.
            </ClientItemDetail>
          </ClientItemDetailContainer>
          <ClientItemImageContainer>
            <ClientItemImage src={ClientThree} />
          </ClientItemImageContainer>
        </ClientItemsContainer>
      </ClientContainer>
    </WholeCLientContainer>
  );
};

// FEEDBACK CONTAINER CODES
const WholeFeedbackCon = () => {
  const Feedback = [
    {
      Name: "Client Director",
      Feedback:
        "“NICE Work Team! only about 34 mins left till close. Special Thanks for Vizcom Extra Efforts !! and Sharon for hoping on handling TL duties!! Awesome team thanks again!!”",
      imageUrl: UserOne,
    },
    {
      Name: "Client Account Manager",
      Feedback:
        "“Hey Roger, First off I really wanted to appreciate all the efforts put in by you. You really rose to the occasion and this can not go by unnoticed. Heartfelt thanks to you and the team at Vizcom This is huge for us Roger, shows what kind of partner you/Vizcom is. I would like to look at TL positions for your team. We can discuss further once this storm blows over. Thank you again.”",
      imageUrl: UserTwo,
    },
  ];

  return (
    <FeedbackContainer>
      <FeedbackTitle>Clients Feedback</FeedbackTitle>
      <FeedWholeItemsContainer>
        {Feedback.map((feedback, index) => (
          <FeedbackItemContainer key={index}>
            <FeedPhotoContainer>
              <FeedImageContaner>
                <FeedUserPhoto
                  src={feedback.imageUrl}
                  alt={`Image for ${feedback.Name}`}
                />
              </FeedImageContaner>
            </FeedPhotoContainer>
            <FeedBottomContainer>
              <FeedUserName>{feedback.Name}</FeedUserName>
              <QuoteContainer>
                <QuoteImage src={Qoute} />
              </QuoteContainer>
              <FeedMessage>{feedback.Feedback}</FeedMessage>
            </FeedBottomContainer>
          </FeedbackItemContainer>
        ))}
      </FeedWholeItemsContainer>
    </FeedbackContainer>
  );
};

// SERVICES CONTAINER

// FREQUENTLY ASKED QUESTIONS CONTAINER
const WholeFrequentlyAsked = () => {
  const QandA = [
    {
      Question: "Are you hiring?",
      Answer: [
        "Our hirings are currently postponed as of the moment. Please be advised that you can still proceed with the pre-qualification by filling out the google form on the link below. Thank you! Make sure to use a computer in filling out the form.",
        " If you are interested, Kindly fill out this form and follow the instructions provided:http://bit.ly/3HqOFow. ",
        "You may also directly send your Resume/CV to our email address, hiring@vizcom.ph.",
      ],
    },
    {
      Question: "May I ask for your mobile number ?",
      Answer: ["Mobile Number: 0956-448-4530"],
    },
    {
      Question: "Do you have a Work From Home (WFH) accounts?",
      Answer: [
        "None. Our clientele preference is center-based. As of this time, we are not keen to implement the Work From Home setup.",
      ],
    },
    {
      Question: "What are the vacant positions in your company?",
      Answer: [
        "As of now, all the positions have been filled up. However, we are still entertaining every application and will profile you to our additional account and business partners soon.",
      ],
    },
    {
      Question: "What is the basic salary or compensation package offered?",
      Answer: [
        "Salary depends on the result of required evaluation to your experience; technical skills and English proficiency. We shall gladly discuss appropriate salary for applicants once they reach the final interview.",
        " Should you wish to apply? Please fill out the Google form link provided for contact information.",
      ],
    },
    {
      Question: "Are you still hiring?",
      Answer: [
        "Yes, Send us your full name and number to start your application with us or either, send us your CV/Resume at hiring@vizcom.ph.",
        " To set an interview appointment, please fill up the following link:http://bit.ly/3HqOFow",
      ],
    },
    {
      Question: "Where are you located?",
      Answer: [
        "We are currently making a huge transition in making two possible hubs in Nueva Vizcaya instead of one. We will contact you and reach out to you soon once our offices are all finished and well-furnished.",
      ],
    },
    {
      Question: "May I ask for an appointment?",
      Answer: [
        "As of now, we can't cater to your needs for an appointment with us. We will inform you soon of the most possible time that you can reach out to us.",
      ],
    },
    {
      Question:
        "What are the qualifications for agents under Pizza/Food Ordering Campaign",
      Answer: [
        "1. English proficiency with good comprehension",
        "2. Knowledge of POS systems is an advantage",
        "3. Above average Computer Navigation skills",
        "4. Above 35WPM typing skill level",
        "5. Active Listening skills",
        "6. Willing to undergo a Traineeship for skill development",
        "7. Willing to work on shifting schedules",
      ],
    },
    {
      Question:
        "What time, day can we come to the center to process our application? (Walk-in)",
      Answer: [
        "We will reach out to you soon after we setup our new location.",
      ],
    },
  ];

  return (
    <WholeFAQContainer id="faq">
      <WholeFAQTitleContainer>
        <WholeFAQTitle>Frequently Asked Questions</WholeFAQTitle>
      </WholeFAQTitleContainer>

      <FAQMainContainer>
        {QandA.map((Sample, index) => (
          <FAQItemContainer key={index}>
            <FAQItemTitle>{Sample.Question}</FAQItemTitle>
            <FAQHoverContainer className="FAQ-hover-con">
              <HoverFAQ>
                <FAQHoverTitleContainer>
                  <FAQHoverTitle>{Sample.Question}</FAQHoverTitle>
                </FAQHoverTitleContainer>
                <FAQHoverHr />
                {Sample.Answer.map((Ans, ind) => {
                  return (
                    <FAQHoverAnswerContainer>
                      <FAQHoverAnswer>{Ans}</FAQHoverAnswer>
                    </FAQHoverAnswerContainer>
                  );
                })}
              </HoverFAQ>
            </FAQHoverContainer>
          </FAQItemContainer>
        ))}
      </FAQMainContainer>
    </WholeFAQContainer>
  );
};

export default function Home() {
  return (
    <WholeHomepageBody>
      <HomepageBody>
        <MobileWholeBannerContainer />
        <WholeBanner />
        <WholeHiThere />
        <MobileWholeHiThere />
        <WholeAboutUs />
        <WholeMissionVision />
        <WholeMeetTheTeam />
        <WholeCoreValues />
        <WholeSpecialization />
        <WholeTraining />
        <WholeClientTraining />
        <WholeFeedbackCon />
        <WholeFrequentlyAsked />
      </HomepageBody>
      <Footer />
    </WholeHomepageBody>
  );
}
