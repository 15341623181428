import styled from "styled-components";
import { devices } from "../Components/Devices";

export const ServicesMainContainer = styled.div`
  @media ${devices.mobile} {
    width: 100%;
  }

  @media ${devices.tablet} {
    width: 100%;
  }

  @media ${devices.laptopS} {
    width: 100%;
  }

  @media ${devices.laptopL} {
    width: 80%;
    margin-left:10%;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }
`;

export const ServicesWholeTitle = styled.p`
  font-size: 48px;
  font-weight: 700;
  color: #f7d210;
  padding: 10px 0;
  margin: 20px 0;

  @media ${devices.mobile} {
    font-size: 40px;
    font-weight: 700;
    color: #f7d210;
    margin: 50px 0 0 0;
  }

  @media ${devices.tablet} {
    font-size: 40px;
    font-weight: 700;
    color: #f7d210;
    margin: 50px 0 0 0;
  }

  
  @media ${devices.laptopS} {
    font-size: 40px;
    font-weight: 700;
    color: #f7d210;
    margin: 70px 0 0 0;
  }
`;

export const ServicesItemContainer = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 800px;
    margin: 0;
    position: relative;
    display: flex;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 600px;
    margin: 0 0 100px 0;
    position: relative;
  }

  @media ${devices.laptopS} {
    width: 100%;
    height: 560px;
    margin: 0 0 100px 0;
    position: relative;
  }

  @media ${devices.laptopL} {
    width: 100%;
    height: 580px;
    margin: 0 0 100px 0;
    position: relative;
  }
`;

export const ServiceItemImage = styled.img`
  @media ${devices.mobile} {
    display: none;
  }

  @media ${devices.tablet} {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    border-radius: 0 0 40px 40px;
  }

  @media ${devices.laptopS} {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    border-radius: 0 0 40px 40px;
  }

  @media ${devices.laptopL} {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    border-radius: 0 0 40px 40px;
  }
`;

export const ServicesItemCon = styled.div`
  @media ${devices.mobile} {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
  }
`;

export const ServiceTitle = styled.p`
  &:hover {
    text-decoration: underline;
  }

  @media ${devices.mobile} {
    font-size: 18px;
    font-weight: 700;
    color: #00509d;
    transition: 0.5s;
    cursor: pointer;
  }

  @media ${devices.tablet} {
    font-size: 20px;
    font-weight: 700;
    color: #00509d;
    transition: 0.5s;
    cursor: pointer;
    padding: 10px;
  }

  @media ${devices.laptopS} {
    font-size: 25px;
    font-weight: 700;
    color: #00509d;
    transition: 0.5s;
    cursor: pointer;
    padding: 10px;
  }

  @media ${devices.laptopL} {
    font-size: 28px;
    font-weight: 700;
    color: #00509d;
    transition: 0.5s;
    cursor: pointer;
    padding: 10px;
  }
`;

export const BottomContainer = styled.div`
  @media ${devices.mobile} {
    height: 100%;
    width: 100%;
    display: flex;
    z-index: 1;
  }

  @media ${devices.tablet} {
    height: 99.99%;
    width: 50%;
    display: flex;
    z-index: 1;
  }
`;

export const DetailsContainer = styled.div`
  @media ${devices.mobile} {
    width: 98%;
    padding: 10px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 0 0 0 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;

  }


  @media ${devices.tablet} {
    height: 107%;
    width: 100%;
    margin-top: 12%;
    word-wrap: wrap;
    background-color: white;
    border-radius: 0 0 0 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media ${devices.laptopS} {
    height: 87%;
    width: 100%;
    margin-top: 12%;
    background-color: white;
    border-radius: 0 0 0 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  @media ${devices.laptopL} {
    height: 87%;
    width: 100%;
    margin-top: 12%;
    background-color: white;
    border-radius: 0 0 0 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
`;

export const ServicePhoto = styled.img`
  @media ${devices.mobile} {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  @media ${devices.tablet} {
    height: 90%;
    width: 100%;
    object-fit: cover;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  @media ${devices.laptopS} {
    height: 90%;
    width: 100%;
    object-fit: cover;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  @media ${devices.laptopL} {
    height: 90%;
    width: 100%;
    object-fit: cover;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
`;

export const ServiceDetails = styled.p`
  @media ${devices.mobile} {
    font-size: 13px;
    color: #2c2b2b;
    font-weight: 400;
    padding: 5px 10px;
    text-align: justify;
  }

  @media ${devices.tablet} {
    font-size: 14.3px;
    color: #2c2b2b;
    font-weight: 400;
    padding: 5px 10px;
    text-align: justify;
  }

  @media ${devices.laptopS} {
    font-size: 15px;
    color: #2c2b2b;
    font-weight: 400;
    padding: 5px 10px;
    text-align: justify;
  }

  @media ${devices.laptopL} {
    font-size: 16.5px;
    color: #2c2b2b;
    font-weight: 400;
    padding: 5px 10px;
    text-align: justify;
  }
`;

export const BannerService = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const BanHoverContainer = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  justify-content: center;
  box-sizing: border-box;
`;

export const BanCon = styled.div`
  &:hover {
    .BannerHoverContainer {
      display: flex;
    }
  }
`;

export const BannerServiceContainer = styled(BanCon)`
  @media ${devices.mobile} {
    width: 100%;
    position: relative;
   top: 55px;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: 500px;
    position: relative;
    top: 55px;
  }

  @media ${devices.laptopS} {
    width: 100%;

    height: 700px;
    position: relative;
    top: 65px;
  }

  @media ${devices.laptopL} {
    width: 100%;
    height: 700px;
    position: relative;
  }
`;

export const BannerVideo = styled.video`
  @media ${devices.mobile} {
    width: 100%;
    object-fit: cover;
  }

  @media ${devices.tablet} {
    width: 100%;
    object-fit: cover;
  }

  @media ${devices.laptopS} {
    width: 100%;
    object-fit: cover;
  }

  @media ${devices.laptopL} {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const PlayIconContainer = styled.div`
  @media ${devices.mobile} {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 30%;
    left: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 120px;
    height: 120px;
    position: absolute;
    top: 30%;
    left: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 30%;
    left: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PlayIcon = styled.img`
  height: 90%;
  filter: invert();
`;
