import "./App.css";
import { BrowserRouter,Routes,Route } from "react-router-dom";

// COMPONENT IMPORT
import Home from "./Page/Home";
import { GlobalStyle } from "./Styles/GlobalStyle";
import SignIn from "./Page/SignIn";
import SignUp from "./Page/SignUp";
import Header from "./Components/Header";
import Services from "./Page/Services";

function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
      <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/services' element={<Services />} />
        </Routes>
      </BrowserRouter>
      <GlobalStyle />


    </div>
  );
}

export default App;
