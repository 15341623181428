import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  HeaderContainer,
  ToggleContainer,
  ToggleImage,
  Container,
  ContainerService,
  Wrapper,
  VizLogo,
  ContactUsButton,
  WrapperItem,
  HeaderLink,
  HeaderLinkContainer,
} from "../Styles/Header.styled";

// IMAGES IMPORT
import ToggleButton from "../Images/Toogle.png";
import VizComLogo from "../Images/LogoName.png";
import CloseIcon from "../Images/closeicon.png";

import ServicesPage from "../Page/Services";

const Services = ({ setservicesOpen }) => {
  return (
    <>
      <ContainerService onClick={(e) => setservicesOpen(false)}>
        <ServicesPage />
      </ContainerService>
    </>
  );
};

const MenuDropDown = ({ setOpen }) => {
  const [openService, setservicesOpen] = useState(false);

  const handleScrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
      setOpen(false);
    }
  };

  return (
    <>
      <Container>
        <Wrapper>
          <WrapperItem onClick={e => setOpen(false)}>
            <Link to='/' style={{textDecoration:'none',color:'black'}}>
              Home
            </Link>
          </WrapperItem>
          <WrapperItem onClick={() => handleScrollToSection("about")}>
            About us
          </WrapperItem>
          <WrapperItem onClick={e => setOpen(false)}>
            <Link to='/services' style={{textDecoration:'none',color:'black'}}>
              Services
            </Link>
          </WrapperItem>
          {openService && <Services setservicesOpen={setservicesOpen} />}
        </Wrapper>
      </Container>
    </>
  );
};

export default function Header() {
  const [open, setOpen] = useState(false);
  const [openService, setservicesOpen] = useState(false);

  const toggleImage = () => {
    setOpen(!open);
  };

  const handleScrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const facebookLink =
    "https://www.facebook.com/profile.php?id=100086389816868";

  return (
    <>
      <HeaderContainer>
        <ToggleContainer onClick={toggleImage}>
          <ToggleImage src={open ? CloseIcon : ToggleButton} />
        </ToggleContainer>
        <VizLogo src={VizComLogo} />

        <HeaderLinkContainer>
          <HeaderLink>
            <Link to='/' style={{textDecoration:'none',color:'gray'}}>
              Home
            </Link>
          </HeaderLink>

          <HeaderLink onClick={() => handleScrollToSection("about")}>
            About us
          </HeaderLink>


          <HeaderLink>
            <Link to='/services' style={{textDecoration:'none',color:'gray'}}>
            Services
            </Link>
          </HeaderLink>

          <a href={facebookLink} target="blank">
            <ContactUsButton>Contact us</ContactUsButton>
          </a>
        </HeaderLinkContainer>

        {openService && <Services setservicesOpen={setservicesOpen} />}
        {open && <MenuDropDown setOpen={setOpen} />}
      </HeaderContainer>
    </>
  );
}
