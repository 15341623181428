import styled from "styled-components";
import { devices } from "../Components/Devices";

export const HeaderContainer = styled.div`
  @media ${devices.mobile} {
    height: 55px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background-color: white;
    z-index: 99;
  }

  @media ${devices.tablet} {
    height: 55px;
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media ${devices.laptopS} {
    height: 65px;
    max-width: 1366px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media ${devices.laptopL} {
    height: 65px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ToggleContainer = styled.div`
  @media ${devices.mobile} {
    width: 55px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.tablet} {
    width: 55px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${devices.laptopS} {
    display: none;
  }

  @media ${devices.laptopL} {
    display: none;
  }
`;

export const ToggleImage = styled.img`
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 55px);
  z-index: 99;
  position: absolute;
  top: 55px;
  display: flex;
  justify-content: center;
  overflow: auto;
  background-color: white;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1a66cc26;
    border-radius: 5px;

    &:hover {
      background-color: #00296b;
    }
  }
`;

export const ContainerService = styled.div`
  /* &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1a66cc26;
    border-radius: 5px;

    &:hover {
      background-color: #00296b;
    }
  } */

  &::-webkit-scrollbar {
    display: none;
  }
  @media ${devices.mobile} {
    width: 100%;
    height: calc(100vh - 55px);
    z-index: 99;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    overflow: auto;
    background-color: white;
  }

  @media ${devices.tablet} {
    width: 100%;
    height: calc(100vh - 55px);
    z-index: 99;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    overflow: auto;
    background-color: white;
  }

  @media ${devices.laptopS} {
    width: 100%;
    height: calc(100vh - 55px);
    z-index: 99;
    position: absolute;
    top: 65px;
    display: flex;
    justify-content: center;
    overflow: auto;
    background-color: white;
  }

  @media ${devices.laptopL} {
    width: 100%;
    height: calc(100vh - 65px);
    z-index: 99;
    position: absolute;
    top: 65px;
    display: flex;
    justify-content: center;
    overflow: auto;
    background-color: white;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #003f88f2;
  z-index: 100;
  border-top: 1px solid #003f88;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
`;

export const WrapperItem = styled.div`
  width: 80%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  background-color: lightblue;
  border: none;
  border-radius: 20px;
  margin: 10px 0;
  box-shadow: 0px 4px 4px 0px #003f88;
`;

export const HeaderLinkContainer = styled.div`
  @media ${devices.laptopS} {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const HeaderLink = styled.p`
  @media ${devices.mobile} {
    display: none;
  }

  @media ${devices.laptopS} {
    display: flex;
    font-size: 18px;
    font-weight: 400;
    margin: 0 20px;
    cursor: pointer;
    transition: 0.3s;
    color: #939393;

    &:hover {
      color: blue;
    }
  }
`;

export const ServiceConHover = styled.div`
  display: none;
  flex-direction: column;
  width: 25%;
  height: 100vh;
  background-color: #003f88f2;
  position: absolute;
  top: 0;
  right: 0;
`;

export const SerCon = styled.div`
  &:hover {
    .Service-Hover {
      display: flex;
    }
  }
`;

export const VizLogo = styled.img`
  height: 90%;
`;

export const ContactUsButton = styled.button`
  background-color: #003f88;
  border: none;
  color: white;
  padding: 0 20px;
  height: 40px;
  border-radius: 20px;

  &:hover {
    background-color: lightblue;
    color: black;
  }

  &:active {
    background-color: #003f88;
    color: white;
  }
`;

export const ServicesContainer = styled.div`
  @media ${devices.mobile} {
    width: 450px;
    z-index: 99;
    display: flex;
    flex-direction: column;
  }

  @media ${devices.tablet} {
    width: 800px;
    z-index: 99;
    display: flex;
    flex-direction: column;
  }

  @media ${devices.laptopS} {
    width: 1366px;
    z-index: 99;
    background-color: white;
    position: absolute;
  }

  @media ${devices.laptopL} {
    width: 100%;
    z-index: 99;
    background-color: white;
  }
`;

export const WholeServiceTitle = styled.p`
  font-size: 40px;
  font-weight: 700;
  color: #f7d210;
  margin-bottom: 30px;
  text-align: center;
`;
